import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import auth_axios, { authendpoints } from 'src/utils/authAxios';

import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router';
import { INTERCOM_API_BASE, INTERCOM_APP_ID, PATH_AFTER_LOGIN } from 'src/config-globals';
import { DOCUSIGN_KEY, STORAGE_KEY, UserStatusType } from 'src/config/constants';
import messages from 'src/config/messages';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { generateHMACHash } from 'src/utils/cryptoUtils';
import { AuthContext } from './auth-context';
import { setSession } from './utils';

const initialState = {
  user: null,
  loading: true,
  system_defaults: null,
  is_mainnet_network: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'SET_SYSTEM_DEFAULTS') {
    return {
      ...state,
      system_defaults: action.payload,
    };
  }
  if (action.type === 'TOGGLE_MAINNET') {
    return {
      ...state,
      is_mainnet_network: action.payload,
    };
  }
  if (action.type === 'EMAIL_VERIFIED') {
    return {
      ...state,
      is_email_verified: action.payload,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const router = useRouter();
  const navigate = useNavigate();
  const auth = useAuth();
  const checkAuthenticated =
    state.user || auth?.isAuthenticated ? 'authenticated' : 'unauthenticated';
  const status = state.loading || auth?.isLoading ? 'loading' : checkAuthenticated;

  const SSOToken = auth?.user?.access_token ?? null;

  useEffect(() => {
    if (auth?.isAuthenticated && SSOToken && state.user === null) {
      initialize(SSOToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated, SSOToken]);

  useEffect(
    () =>
      auth.events.addAccessTokenExpiring(() => {
        if (
          alert(
            "You're about to be signed out due to inactivity. Press continue to stay signed in."
          )
        ) {
          auth.signinSilent();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.events, auth.signinSilent]
  );

  const initialize = useCallback(async (authKey) => {
    try {
      // const authToken = sessionStorage.getItem(STORAGE_KEY) || authKey;
      const authToken = localStorage.getItem(STORAGE_KEY) || authKey;

      if (authToken) {
        setSession(authToken);

        const response = await auth_axios.get(authendpoints.authnew.userinfo);

        const userInfo = response.data;
        if (userInfo?.status === UserStatusType.INVITED) {
          router.push(paths.auth.jwt.updatePassword);
          dispatch({
            type: 'INITIAL',
            payload: {
              ...state,
              user: null,
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: userInfo,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
      await get_blockchain_server();
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGIN
  const login = useCallback(async (username, password, app_url, forwardedFile, registerAction) => {
    const data = {
      username,
      password,
      app_url,
    };
    try {
      const response = await auth_axios.post(authendpoints.authnew.login, data);
      // NOTE - destructure user response
      // const { authToken, user } = response.data;
      // setSession(authToken);
      localStorage.setItem('refreshToken', response?.data?.refreshToken);

      dispatch({
        type: 'INITIAL',
        payload: {
          ...state,
          user: null,
        },
      });
      if (response?.data?.user?.status === UserStatusType.INVITED) {
        setSession(response?.data?.authToken);
        router.push(paths.auth.jwt.updatePassword);
      } else {
        setSession(response?.data?.authToken);
        const currentUnixTime = format(new Date(), 't');

        const HMACHash = await generateHMACHash(response?.data?.user?.email);

        window.Intercom('boot', {
          api_base: INTERCOM_API_BASE,
          app_id: INTERCOM_APP_ID,
          // name: response?.data?.user?.name, // Full name
          email: response?.data?.user?.email, // the email for your user
          // created_at: currentUnixTime, // Signup date as a Unix timestamp
          user_hash: HMACHash,
        });
        dispatch({
          type: 'INITIAL',
          payload: {
            user: response?.data?.user,
          },
        });
        if (forwardedFile && registerAction) {
          navigate(paths.dashboard.registration, { state: { forwardedFile } });
        } else if (forwardedFile && !registerAction) {
          navigate(paths.dashboard.file_action, { state: { forwardedFile } });
        } else {
          router.push(returnTo || PATH_AFTER_LOGIN);
        }
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err?.message ?? err, {
        variant: 'error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(
    async (payload, forwardedFile, registerFileAction, file, onClose) => {
      try {
        const response = await auth_axios.post(authendpoints.authnew.signup, payload);
        // dispatch({
        //   type: 'INITIAL',
        //   payload: {
        //     user: null,
        //   },
        // });
        if (response?.data?.user?.status === UserStatusType.INVITED) {
          enqueueSnackbar(messages.inviteMSG, {
            variant: 'success',
          });
        } else {
          // setSession(response?.data?.authToken);
          dispatch({
            type: 'INITIAL',
            payload: {
              user: response?.data?.user,
            },
          });

          if (forwardedFile && registerFileAction) {
            navigate(paths.dashboard.registration, { state: { forwardedFile } });
          } else if (forwardedFile && !registerFileAction) {
            navigate(paths.dashboard.file_action, { state: { forwardedFile } });
          } else {
            enqueueSnackbar(response?.data, {
              variant: 'success',
            });
            onClose();
            // router.push(paths.auth.amplify.verifyToken);
          }
        }
        // router.push('/');
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error?.message ?? error, {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update_password = useCallback(async (payload) => {
    try {
      const initial_response = await auth_axios.patch(
        authendpoints.authnew.changePassword,
        payload
      );
      // const response = await initialize();

      const authToken = localStorage.getItem(STORAGE_KEY);

      if (authToken) {
        setSession(authToken);

        const response = await auth_axios.get(authendpoints.authnew.userinfo);

        const userInfo = response.data;
        if (userInfo?.status === UserStatusType.ACTIVE) {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: userInfo,
            },
          });
        }
        if (userInfo?.status === UserStatusType.INACTIVE) {
          router.push(`${paths.auth.jwt.verify}?email=${payload?.email}`);
        }
      }
      // enqueueSnackbar(initial_response?.data?.message, {
      //   variant: 'info',
      // });
    } catch (error) {
      enqueueSnackbar(error?.message ?? error, {
        variant: 'error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // VERIFY
  // eslint-disable-next-line consistent-return
  const verify_otp = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.verify, payload);
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
      return response;
    } catch (e) {
      console.error('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', e.message ?? e);

      enqueueSnackbar(e?.message ?? e, {
        variant: 'error',
      });
      throw e?.message ?? e;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RESEND VERIFICATION CODE
  const resendVerificationCode = useCallback(async (email) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.resend_otp, {
        email,
      });
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
    } catch (e) {
      console.error(e.message ?? e);
      enqueueSnackbar(e?.message ?? e, {
        variant: 'error',
      });
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
    localStorage.removeItem(DOCUSIGN_KEY);
    localStorage.removeItem('clio_session');
  }, []);

  const get_defaults = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.system_defaults);
      dispatch({
        type: 'SET_SYSTEM_DEFAULTS',
        payload: response?.data,
      });
    } catch (error) {
      console.error('Error', error);
      dispatch({
        type: 'SET_SYSTEM_DEFAULTS',
        payload: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const forget_password = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.forget_password, payload);
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
      // router.push(paths.auth.jwt.login);
    } catch (e) {
      console.error('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', e.message ?? e);

      // enqueueSnackbar(e?.message ?? e, {
      //   variant: 'error',
      // });
      throw e?.message ?? e;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_forget_password = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(
        authendpoints.authnew.update_forget_Password,
        payload
      );
      navigate(paths.auth.frontpage);
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
    } catch (e) {
      console.error(e.message ?? e);
      enqueueSnackbar(e?.message ?? e, { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.verify_token}/${payload}`);
      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return null;
    }
  }, []);

  const verify_user_registerToken = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.register_verify_token}?email=${payload.email}&token=${payload.token}`
      );

      dispatch({
        type: 'EMAIL_VERIFIED',
        payload: { sucess: true, message: response?.data?.message },
      });
      router.push(paths.auth.jwt.verifySuccess);
    } catch (e) {
      console.error(e.message ?? e);

      dispatch({
        type: 'EMAIL_VERIFIED',
        payload: { sucess: false, message: e.message },
      });
      router.push(paths.auth.jwt.verifySuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_blockchain_server = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.toggle_blockchain_server);
      if (response?.data?.value === ('true' || true)) {
        dispatch({
          type: 'TOGGLE_MAINNET',
          payload: true,
        });
      } else {
        dispatch({
          type: 'TOGGLE_MAINNET',
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: 'TOGGLE_MAINNET',
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    get_defaults();
    get_blockchain_server();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
      system_defaults: state.system_defaults,
      get_defaults,
      update_password,
      verify_otp,
      resendVerificationCode,
      initialize,
      forget_password,
      update_forget_password,
      verify_token,
      verify_user_registerToken,
      get_blockchain_server,
      is_mainnet_network: state.is_mainnet_network,
      is_email_verified: state.is_email_verified,
    }),
    [
      login,
      logout,
      register,
      state.user,
      status,
      state.system_defaults,
      get_defaults,
      update_password,
      state.is_email_verified,
      verify_otp,
      resendVerificationCode,
      initialize,
      forget_password,
      update_forget_password,
      verify_token,
      verify_user_registerToken,
      get_blockchain_server,
      state.is_mainnet_network,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
