import PropTypes from 'prop-types';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { JwtLoginView, JwtRegisterView } from 'src/sections/auth/jwt';

// ----------------------------------------------------------------------

export default function LoginDialog({
  open,
  onClose,
  openRegister,
  file,
  register,
  registerFileAction,
  handleRegisterButtonClick,
  ...other
}) {
  return (
    <Dialog
      fullWidth
      maxWidth={other.maxWidth ? other.maxWidth : 'sm'}
      open={open}
      onClose={() => {
        onClose();
        handleRegisterButtonClick(false);
      }}
      {...other}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
          handleRegisterButtonClick(false);
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ p: 5 }}>
        {openRegister ? (
          <JwtRegisterView
            onRegisterButtonClick={handleRegisterButtonClick}
            altUI
            file={file}
            registerFileAction={registerFileAction}
            onClose={onClose}
          />
        ) : (
          <JwtLoginView
            onRegisterButtonClick={handleRegisterButtonClick}
            altUI
            file={file}
            register={register}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  openRegister: PropTypes.bool,
  handleRegisterButtonClick: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  register: PropTypes.bool,
  registerFileAction: PropTypes.bool,
};
