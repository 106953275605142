import { useTheme } from '@mui/material/styles';
import { m, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SpinnerLoader = ({ loading, loadertext, onTimeout }) => {
  const [timerActive, setTimerActive] = useState(true);
  const theme = useTheme();
  const controls = useAnimation();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const spinnerVariants = {
    initial: { rotate: 0 },
    animate: { rotate: 360 },
  };

  const textVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { repeat: Infinity, duration: 0.5, repeatType: 'reverse' } },
  };

 useEffect(() => {
  if (loading) {
    controls.start('animate');

    // Set a timeout to clear localStorage after 10 seconds
    const timeout = setTimeout(() => {
      if (timerActive) {
        console.warn('Loader timeout reached. Clearing localStorage...');
        localStorage.clear();
        setTimerActive(false); // Prevent repeated timeout actions
        if (onTimeout) {
          onTimeout();
        }
      }
    }, 5000); // 10 seconds 10000

    return () => clearTimeout(timeout); // Cleanup timeout
  } 
    controls.start('initial');
    setTimerActive(false); // Stop the timer if loading finishes
  

  // Explicitly return undefined for the non-loading case
  return undefined;
}, [loading, controls, timerActive, onTimeout]);


  return (
    <m.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <m.div
        style={{
          width: 50,
          height: 50,
          border: `5px solid ${PRIMARY_MAIN}`,
          borderTop: '5px solid #fff',
          borderRadius: '50%',
        }}
        variants={spinnerVariants}
        initial="initial"
        animate="animate"
        transition={{ duration: 1, ease: 'linear', repeat: Infinity }}
      />

      <m.h2
        style={{
          marginTop: 20,
          fontSize: 16,
          fontWeight: 'bold',
          color: '#333',
        }}
        variants={textVariants}
        initial="initial"
        animate="animate"
        transition={{ duration: 0.5 }}
      >
        {loadertext}
      </m.h2>
    </m.div>
  );
};

SpinnerLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadertext: PropTypes.string.isRequired,
  onTimeout: PropTypes.func,
};

export default SpinnerLoader;
