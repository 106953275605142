// old email that didn't allow numbers in the domain name
// const EMAIL = /^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z.-]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$/;
const EMAIL = /^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9.-]+([.-]?[a-zA-Z]))[.]{1}[a-zA-Z]{2,}$/;
// const EMAIL = /^[a-zA-Z]+([.-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}$/;
const PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
const INT_NUMBER = /^[0-9]{1,2}$/g;
const NUMBER = /^[^0-9]$/;
const PHONENUMBER = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const NAME = /^[A-Za-z\s]+$/;
const URL =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,6}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?$/;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  EMAIL,
  PASSWORD,
  INT_NUMBER,
  NAME,
  NUMBER,
  PHONENUMBER,
  URL,
};
