import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import messages from 'src/config/messages';
import auth_axios, { authendpoints } from 'src/utils/authAxios';
// import { SET_CONTENTS, SET_DOCUMENTS } from './storeActions';

const isValidToken = (time) => {
  if (!time) {
    return false;
  }
  const currentTime = Date.now() / 1000;
  return time > currentTime;
};

export const PlatformContext = createContext({
  adobe_generate_token: async () => {},
  adobe_refresh_token: async () => {},
  check_adobe_session: async () => {},
  is_valid_adobe_session: false,
  adobe_access_token: null,
  adobe_create_smart_doc: async () => {},
  adobe_config: null,
  adobe_set_config: async () => {},
  adobe_update_config: async () => {},
  adobe_get_config: async () => {},
  adobe_logout: async () => {},
  platform_default: null,
  set_platform_default: async () => {},
  get_platform_default: async () => {},
  update_platform_default: async () => {},
  adobe_agreement: async () => {},
  adobe_get_webhook: async () => {},
  adobe_webhooks: [],
  verifiablePresentation: async () => {},
});

const SET_IS_VALID_SESSION = 'SET_IS_VALID_SESSION';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_PLATFORM_DEFAULT = 'SET_PLATFORM_DEFAULT';
const SET_CLIO_CONFIG = 'SET_CLIO_CONFIG';
const SET_ADOBE_WEBHOOKS = 'SET_ADOBE_WEBHOOKS';
const SET_SUBSCRIBE_EVENT = 'SET_SUBSCRIBE_EVENT';
const SET_DOC_STATUS = 'SET_DOC_STATUS';
const SET_VERIFIABLE_PRESENTATION = 'SET_VERIFIABLE_PRESENTATION';
const SET_SIGNING_PLATFORMS = 'SET_SIGNING_PLATFORMS';

function clioReducer(state, action) {
  switch (action.type) {
    case SET_IS_VALID_SESSION:
      return {
        ...state,
        is_valid_adobe_session: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        adobe_access_token: action.payload,
      };

    case SET_CLIO_CONFIG:
      return {
        ...state,
        adobe_config: action.payload,
      };

    case SET_PLATFORM_DEFAULT:
      return {
        ...state,
        platform_default: action.payload,
      };

    case SET_ADOBE_WEBHOOKS:
      return {
        ...state,
        adobe_webhooks: action.payload,
      };
    case SET_SUBSCRIBE_EVENT:
      return {
        ...state,
        docusign_event: action.payload,
      };
    case SET_DOC_STATUS:
      return {
        ...state,
        document_conversation: action.payload,
      };
    case SET_VERIFIABLE_PRESENTATION:
      return {
        ...state,
        verifiable_presentation_data: action.payload,
      };
    case SET_SIGNING_PLATFORMS:
      return {
        ...state,
        render_sign_platofrms: action.payload,
      };

    default:
      return state;
  }
}

function PlatformContextProvider({ children }) {
  const [state, dispatch] = useReducer(clioReducer, {
    is_valid_adobe_session: false,
    adobe_access_token: null,
    adobe_config: null,
  });

  const adobe_generate_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.adobe_generate_token}`,
        payload
      );
      const currentTime = Date.now() / 1000;
      const expirationTime = currentTime + response.data.expires_in;
      const sessionData = {
        ...response.data,
        expiration_time: expirationTime,
      };
      const stringifiedData = JSON.stringify(sessionData);
      const encodeData = btoa(stringifiedData);
      localStorage.setItem('adobe_session', encodeData);

      await check_adobe_session();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_refresh_token = useCallback(async (payload) => {
    try {
      localStorage.removeItem('adobe_session');
      const response = await auth_axios.post(
        `${authendpoints.authnew.adobe_refresh_token}`,
        payload
      );

      const currentTime = Date.now() / 1000;
      const expirationTime = currentTime + response.data.expires_in;
      const sessionData = {
        ...response.data,
        expiration_time: expirationTime,
      };
      const stringifiedData = JSON.stringify(sessionData);
      const encodeData = btoa(stringifiedData);
      localStorage.setItem('adobe_session', encodeData);
      await check_adobe_session();
      return sessionData;
    } catch (error) {
      console.error(error);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const check_adobe_session = useCallback(async () => {
    const encodedAdobeData = localStorage.getItem('adobe_session');
    const decodedString = encodedAdobeData && atob(encodedAdobeData);
    const adobeSession = JSON.parse(decodedString);

    if (adobeSession) {
      const checkValidity = isValidToken(adobeSession?.expiration_time);
      if (checkValidity) {
        dispatch({
          type: SET_IS_VALID_SESSION,
          payload: checkValidity,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: adobeSession?.access_token,
        });
      } else {
        const payload = {
          refresh_token: adobeSession?.refresh_token,
        };
        const response = await adobe_refresh_token(payload);
        const checkRefreshValidity = isValidToken(response.expiration_time);
        dispatch({
          type: SET_IS_VALID_SESSION,
          payload: checkRefreshValidity,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.access_token,
        });
      }
    } else {
      dispatch({
        type: SET_IS_VALID_SESSION,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_create_smart_doc = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.clio_document}`, payload);
      enqueueSnackbar(messages.clioDocUploadSuccessMsg, { variant: 'success' });

      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });

      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    }
  }, []);

  const adobe_set_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.adobe_config}`, payload);
      enqueueSnackbar(messages.adobePostConfigSuccessMsg, { variant: 'success' });

      await adobe_get_config();
      enqueueSnackbar(messages.configAddedMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_update_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(`${authendpoints.authnew.adobe_config}`, payload);
      enqueueSnackbar(messages.adobeUpdateConfigSuccessMsg, { variant: 'success' });

      await adobe_get_config();
      enqueueSnackbar(messages.configUpdateMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_get_config = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.adobe_config);
      dispatch({
        type: SET_CLIO_CONFIG,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
  }, []);

  const adobe_logout = useCallback(async () => {
    try {
      const encodedAdobeData = localStorage.getItem('adobe_session');
      const decodedString = encodedAdobeData && atob(encodedAdobeData);
      const decodedAdobeSessionData = JSON.parse(decodedString);

      const payload = {
        token: decodedAdobeSessionData.access_token,
      };

      const response = await auth_axios.post(decodedAdobeSessionData.api_access_point, payload, {
        headers: {
          Authorization: decodedAdobeSessionData.access_token,
        },
      });

      localStorage.removeItem('adobe_session');
      dispatch({
        type: SET_IS_VALID_SESSION,
        payload: false,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
  }, []);

  const set_platform_default = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.applications_config, payload);
      dispatch({
        type: SET_PLATFORM_DEFAULT,
        payload: response.data,
      });
      await get_platform_default({ hideError: false });
      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_platform_default = useCallback(async ({ hideError }) => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.applications_config);
      dispatch({
        type: SET_PLATFORM_DEFAULT,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      if (!hideError) {
        enqueueSnackbar(
          Array.isArray(error?.message) ? error?.message[0] : error?.message || error,
          {
            variant: 'warning',
          }
        );
      }
    }
  }, []);

  const update_platform_default = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.applications_config, payload);
      dispatch({
        type: SET_PLATFORM_DEFAULT,
        payload: response.data,
      });
      await get_platform_default({ hideError: false });
      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_agreement = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.adobe_agreement, payload);

      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
      return response?.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adobe_get_webhook = useCallback(async (token) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.adobe_webhook}?token=${token}`
      );
      dispatch({
        type: SET_ADOBE_WEBHOOKS,
        payload: response.data.data,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const webhooks_list_test = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.webhooks_test}`, payload);
      // enqueueSnackbar(messages.clioDocUploadSuccessMsg, { variant: 'success' });
      return response?.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });

      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    }
  }, []);
  // eslint-disable-next-line consistent-return
  const get_events = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.webhooks_test);
      return response.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const post_document_transfer = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.document_transfer}`, payload);

      return response?.data;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });

      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    }
  }, []);

  const subscribeEvent = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.eventSubscribe}/${payload?.eventname}`
      );
      dispatch({
        type: SET_SUBSCRIBE_EVENT,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'warning',
      // });
    }
  }, []);

  const documentConversation = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.document_conversation_status}/${payload}`
      );
      dispatch({
        type: SET_DOC_STATUS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'warning',
      // });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const verifiablePresentation = useCallback(async (payload, id) => {
    const newPayload = payload?.shared_credentials.join(',');
    // const newPayload = payload?.credential?.credential_type;
    // const formattedPayload =
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.verifiable_presentation}?type=${newPayload}&integraId=${id}`
      );
      dispatch({
        type: SET_VERIFIABLE_PRESENTATION,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      console.error(error);
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'warning',
      // });
      // throw new Error(error);
      enqueueSnackbar('It already has an old version of the verified credential.', {
        variant: 'warning',
      });
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const renderSigningPlatforms = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.signing_platform}`);
      dispatch({
        type: SET_SIGNING_PLATFORMS,
        payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      return null;
      // throw new Error(error);
    }
  }, []);

  const documentApi = useCallback(async (integraId, documentPayload) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.document}/${integraId}`,
        documentPayload
      );
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      adobe_generate_token,
      adobe_refresh_token,
      post_document_transfer,
      check_adobe_session,
      is_valid_adobe_session: state.is_valid_adobe_session,
      adobe_access_token: state.adobe_access_token,
      get_events,
      adobe_create_smart_doc,
      adobe_config: state.adobe_config,
      adobe_set_config,
      adobe_update_config,
      adobe_get_config,
      adobe_logout,
      platform_default: state.platform_default,
      set_platform_default,
      get_platform_default,
      update_platform_default,
      adobe_agreement,
      adobe_get_webhook,
      webhooks_list_test,
      adobe_webhooks: state.adobe_webhooks,
      docusign_event: state.docusign_event,
      subscribeEvent,
      documentConversation,
      document_conversation: state.document_conversation,
      verifiablePresentation,
      verifiable_presentation_data: state.verifiable_presentation_data,
      renderSigningPlatforms,
      render_sign_platofrms: state.render_sign_platofrms,
      documentApi,
    }),

    [
      adobe_generate_token,
      adobe_refresh_token,
      webhooks_list_test,
      post_document_transfer,
      check_adobe_session,
      state.is_valid_adobe_session,
      state.adobe_access_token,
      get_events,
      adobe_create_smart_doc,
      state.adobe_config,
      adobe_set_config,
      adobe_update_config,
      adobe_get_config,
      adobe_logout,
      state.platform_default,
      set_platform_default,
      get_platform_default,
      update_platform_default,
      adobe_agreement,
      adobe_get_webhook,
      state.adobe_webhooks,
      state.docusign_event,
      subscribeEvent,
      documentConversation,
      state.document_conversation,
      verifiablePresentation,
      state.verifiable_presentation_data,
      renderSigningPlatforms,
      state.render_sign_platofrms,
      documentApi,
    ]
  );

  return <PlatformContext.Provider value={memoizedValue}>{children}</PlatformContext.Provider>;
}

PlatformContextProvider.propTypes = {
  children: PropTypes.node,
};

export default PlatformContextProvider;
