import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import auth_axios, { authendpoints } from 'src/utils/authAxios';
// import { SET_CONTENTS, SET_DOCUMENTS } from './storeActions';
import axios from 'axios';

import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { DOCUSIGN_KEY } from 'src/config/constants';
import messages from 'src/config/messages';

import { CHILKAT_ENDPOINT } from 'src/config-globals';
import { paths } from 'src/routes/paths';
import docusignConfig from 'src/sections/apps/DocuSign/docusign.config';
import { checkDocusignSession } from 'src/utils/docusignSessionHandler';

export const ContentContext = React.createContext({
  contents: [],
  files: [],
  channels: [],
  // channelmessages: [],
  single_conversation: null,
  messages: [],
  transactions: [],
  applications: [],
  myapplications: [],
  singleapp: [],
  fetch_content: async () => {},
  import_doc: async () => {},
  post_myapplication: async () => {},
  post_docusign_doc: async () => {},
  post_update_docusign_doc: async () => {},
  fetch_applications: async () => {},
  fetch_myapplications: async () => {},
  get_app_by_id: async () => {},
  fetch_files: async () => {},
  post_channel: async () => {},
  fetch_channels: async () => {},
  post_message: async () => {},
  fetch_messages: async () => {},
  post_document: async () => {},
  fetch_transactions: async () => {},
  get_app_config_record: async () => {},
  remove_myapplications: async () => {},
  remove_app_config: async () => {},
  add_app_config: async () => {},
  update_app_config: async () => {},
  update_app_config_auth_info: async () => {},
  appConfig: [],
  singleAppConfig: [],
  // get_all_app_config: async () => {},
  get_single_app_config: async () => {},
  myAppDetail: {},
  get_my_app_detail: async () => {},
  transfer_docusign_doc: async () => {},
  get_docusign_auth: async () => {},
  get_app_tags: async () => {},
  appTags: [],
  get_my_application_id_by_guid: async () => {},
  appsSearchByTags: null,
  get_my_application_search_by_tag: async () => {},
  login_docusign_jwt_grant: async () => {},
  // docusign_data: {},
  isSessionLoading: false,
  docusign_authorization: false,
  add_new_application: async () => {},
  update_application: async () => {},
  remove_application: async () => {},
  post_document_no_register: async () => {},
  docusign_document_no_register: async () => {},
  check_docusign_session: async () => {},
  docusign_envelope: async () => {},
  docusign_sending_cermony_link: async () => {},
  envelope_data: null,
  docusign_envelope_recipient: async () => {},
  verify_digest_signature: async () => {},
  identity_exist_by_integraId: async () => {},
  identity_exist_by_documentHash: async () => {},
  integra_identity_data: null,
  get_explorer_url: async () => {},
  blockhain_data: {},
  delete_doc: async () => {},
  set_default_application: async () => {},
  demo_data: [],
  single_demo_data: {},
  get_all_demo: async () => {},
  get_demo: async () => {},
  add_demo: async () => {},
  update_demo: async () => {},
  delete_demo: async () => {},
  toggle_demo: async () => {},
  reset_demo: async () => {},
  system_defaults: {},
  get_defaults: async () => {},
  app_defaults: [],
  get_app_defaults: async () => {},
  update_default_apps: async () => {},
  reset_default_apps: async () => {},
  add_dynamic_forms: async () => {},
  get_dynamic_forms: async () => {},
  dynamic_forms: [],
  fetch_doc_data: async () => {},
  doc_data: null,
  org_data: null,
  get_org_details: async () => {},
  establish_connection: async () => {},
  all_conversations: [],
  document_conversations: async () => {},
  update_conversations: async () => {},
  update_conversation_status: async () => {},
  post_message_file: async () => {},
  post_docusign_doc_v2: async () => {},
  docusign_session: null,
  append_messages: async () => {},
  notifications: [],
  get_notifications: async () => {},
  generate_key: async () => {},
  trusted_data: null,
  check_org_trusted: async () => {},
  generate_signature: async () => {},
  signature_data: null,
  txt_records: null,
  get_txt_records: async () => {},
  verify_dns_signature: async () => {},
  get_all_templates: async () => {},
  all_templates: [],
  template_data: null,
  update_template: async () => {},
  send_verification_code: async () => {},
  all_verifiable_credentials: [],
  get_all_verifiable_credentials: async () => {},
  verify_verification_code: async () => {},
  generate_verifiable_credentials: async () => {},
  generate_org_signature: async () => {},
  org_signature: null,
  verify_org_signature: async () => {},
  find_doc_by_integra_id: async () => {},
  get_transfer_ownership: async () => {},
  read_notifications: async () => {},
  read_chat: async () => {},
  patch_message: async () => {},
  add_stepper_memory: async () => {},
  stepper_memory: null,
  clear_stepper_memory: async () => {},
  get_RSA_keys: async () => {},
  get_Identity_from_Blockchain: async () => {},
  get_signature_validation: async () => {},
  add_intel_config: async () => {},
  update_intel_config: async () => {},
  get_intel_config: async () => {},
  intel_config: null,
  implicit_grant_session: async () => {},
  add_archive: async () => {},
  undo_archive: async () => {},
  archived_documents: null,
  get_archived_documents: async () => {},
  blockchain_pubkey: async () => {},
  cyclr_token: null,
  generate_cyclr_token: null,
  totalCount: 0,
  archivedTotalCount: 0,
  tokenizedCount: 0,
  verify_verifiable_presentation: async () => {},
  is_chat_loading: false,
  fetchAssociateDocsByIntegraId: async () => {},
});

const SET_CONTENTS = 'SET_CONTENTS';
const TOKENIZED_CONTENTS = 'TOKENIZED_CONTENTS';
const SET_CHANNELS = 'SET_CHANNELS';
const SET_SINGLE_CONVERSATION = 'SET_SINGLE_CONVERSATION';
const SET_MESSAGES = 'SET_MESSAGES';
const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
const SET_APPLICATIONS = 'SET_APPLICATIONS';
const SET_MYAPPLICATIONS = 'SET_MYAPPLICATIONS';
const SET_APP = 'SET_APP';
const GET_ALL_CONFIG = 'GET_ALL_CONFIG';
const GET_SINGLE_APP_CONFIG = 'GET_SINGLE_APP_CONFIG';
const GET_MY_APP_DETAIL = 'GET_MY_APP_DETAIL';
const GET_DOCUSIGN_AUTH = 'GET_DOCUSIGN_AUTH';
const GET_APP_TAGS = 'GET_APP_TAGS';
const GET_ACTIONS = 'GET_ACTIONS';
const GET_MY_APP_SEARCH_BY_TAG = 'GET_MY_APP_SEARCH_BY_TAG';
const SET_DOCUSIGN_LOADER = 'SET_DOCUSIGN_LOADER';
const SET_DOCUSIGN_DATA = 'SET_DOCUSIGN_DATA';
const SET_DOCUSIGN_SESSION = 'SET_DOCUSIGN_SESSION';
const SET_ENVELOPE_DATA = 'SET_ENVELOPE_DATA';
const SET_BLOCKCHAIN_DATA = 'SET_BLOCKCHAIN_DATA';
const GET_ALL_DEMO_DATA = 'GET_ALL_DEMO_DATA';
const GET_SINGLE_DEMO_DATA = 'GET_SINGLE_DEMO_DATA';
const SET_APP_DEFAULTS_DATA = 'SET_APP_DEFAULTS_DATA';
const GET_DYNAMIC_FORM = 'GET_DYNAMIC_FORM';
const GET_DOC_DATA = 'GET_DOC_DATA';
const GET_ORG_DATA = 'GET_ORG_DATA';
const SET_CONVERSATION_DATA = 'SET_CONVERSATION_DATA';
const DOCUSIGN_SESSION = 'DOCUSIGN_SESSION';
const SET_APPEND_MESSAGES = 'SET_APPEND_MESSAGES';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
const SET_KEY_PAIRS = 'SET_KEY_PAIRS';
const SET_TRUSTED_DATA = 'SET_TRUSTED_DATA';
const SET_GENERATE_SIGNATURE = 'SET_GENERATE_SIGNATURE';
const SET_TXT_RECORDS = 'SET_TXT_RECORDS';
const SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES';
const SET_SINGLE_TEMPLATE = 'SET_SINGLE_TEMPLATE';
const SET_ALL_VERIFIABLE_CREDENTIALS = 'SET_ALL_VERIFIABLE_CREDENTIALS';
const SET_INTEGRA_LEDGER_DATA = ' SET_INTEGRA_LEDGER_DATA';
const SET_SIGNATURE_DATA = 'SET_SIGNATURE_DATA';
const SET_ORG_SIGNATURE = 'SET_ORG_SIGNATURE';
const SET_EXTARCTED_DATA = 'SET_EXTARCTED_DATA';
const ADD_STEPPER_DATA = 'ADD_STEPPER_DATA';
const CLEAR_STEPPER_DATA = 'CLEAR_STEPPER_DATA';
const SET_SERVER_DOC_META = 'SET_SERVER_DOC_META';
const SET_DOC_DATA = 'SET_DOC_DATA';

const SET_INTEL_CONFIG = 'SET_INTEL_CONFIG';
const SET_ARCHIVED_DOCUMENTS = 'SET_ARCHIVED_DOCUMENTS';
const META_DATA_RENDER = 'META_DATA_RENDER';
const ALL_SCHEMA_FORMS = 'ALL_SCHEMA_FORMS';
const ALL_SCHEMA_FORMS_TYPE = 'ALL_SCHEMA_FORMS_TYPE';
const SCHEMA_FORM_BY_ID = 'SCHEMA_FORM_BY_ID';
const SET_USER_SCHEMA_BY_ID = 'SET_USER_SCHEMA_BY_ID';
const GET_DOCUMENT_ACTIONS = 'GET_DOCUMENT_ACTIONS';
const SET_ACTIONS_ID = 'SET_ACTIONS_ID';
const GET_USER_SCHEMAS = 'GET_USER_SCHEMAS';
const MULTIPLE_SCHEMA_FORMS = 'MULTIPLE_SCHEMA_FORMS';

const SET_CYCLR_TOKEN = 'SET_CYCLR_TOKEN';
const SET_NEW_CYCLR_TOKEN = 'SET_NEW_CYCLR_TOKEN';
const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
const SET_PAGE_COUNT = 'SET_PAGE_COUNT';
const STORE_FRONT_PAGE_FILE = 'STORE_FRONT_PAGE_FILE';
const SET_TOKENIZED_COUNT = 'SET_TOKENIZED_COUNT';
const SET_ARCHIVED_DOCS_COUNT = 'SET_ARCHIVED_DOCS_COUNT';
const SET_CYCLR_MARKETPLACE = 'SET_CYCLR_MARKETPLACE';
const SET_USER_CYCLR_MARKETPLACE = 'SET_USER_CYCLR_MARKETPLACE';
const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
const SET_ARCHIVED_TOTAL_COUNT = 'SET_ARCHIVED_TOTAL_COUNT';
const SET_ARCHIVED_PAGE_COUNT = 'SET_ARCHIVED_PAGE_COUNT';

function contentReducer(state, action) {
  switch (action.type) {
    case SET_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };
    case SET_MYAPPLICATIONS:
      return {
        ...state,
        myapplications: action.payload,
      };
    case SET_APP:
      return {
        ...state,
        singleapp: action.payload,
      };
    case SET_CONTENTS:
      return {
        ...state,
        contents: action.payload,
      };

    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalcount: action.payload,
      };
    case SET_PAGE_COUNT:
      return {
        ...state,
        pagecount: action.payload,
      };
    case SET_ARCHIVED_TOTAL_COUNT:
      return {
        ...state,
        archivedTotalCount: action.payload,
      };
    case SET_ARCHIVED_PAGE_COUNT:
      return {
        ...state,
        archivedPageCount: action.payload,
      };
    case TOKENIZED_CONTENTS:
      return {
        ...state,
        tokenizedContents: action.payload,
      };
    case SET_TOKENIZED_COUNT:
      return {
        ...state,
        tokenizedcount: action.payload,
      };
    case SET_CHANNELS:
      return {
        ...state,
        channels: action.payload,
      };
    case SET_SINGLE_CONVERSATION:
      return {
        ...state,
        single_conversation: action.payload,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_ALL_CONFIG:
      return {
        ...state,
        appConfig: action.payload,
      };
    case GET_SINGLE_APP_CONFIG:
      return {
        ...state,
        singleAppConfig: action.payload,
      };
    case GET_MY_APP_DETAIL:
      return {
        ...state,
        myAppDetail: action.payload,
      };
    case GET_APP_TAGS:
      return {
        ...state,
        appTags: action.payload,
      };
    case GET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };
    case GET_MY_APP_SEARCH_BY_TAG:
      return {
        ...state,
        appsSearchByTags: action.payload,
      };
    case SET_DOCUSIGN_LOADER:
      return {
        ...state,
        isSessionLoading: action.payload,
      };
    case SET_DOCUSIGN_SESSION:
      return {
        ...state,
        docusign_authorization: action.payload,
      };
    case SET_DOCUSIGN_DATA:
      return {
        ...state,
        docusign_data: action.payload,
      };
    case SET_ENVELOPE_DATA:
      return {
        ...state,
        envelope_data: action.payload,
      };
    case SET_BLOCKCHAIN_DATA:
      return {
        ...state,
        blockhain_data: action.payload,
      };
    case GET_ALL_DEMO_DATA:
      return {
        ...state,
        demo_data: action.payload,
      };
    case GET_SINGLE_DEMO_DATA:
      return {
        ...state,
        single_demo_data: action.payload,
      };
    case SET_APP_DEFAULTS_DATA:
      return {
        ...state,
        app_defaults: action.payload,
      };
    case GET_DYNAMIC_FORM:
      return {
        ...state,
        dynamic_forms: action.payload,
      };
    case GET_DOC_DATA:
      return {
        ...state,
        doc_data: action.payload,
      };
    case GET_ORG_DATA:
      return {
        ...state,
        org_data: action.payload,
      };
    case SET_CONVERSATION_DATA:
      return {
        ...state,
        all_conversations: action.payload,
      };
    case DOCUSIGN_SESSION:
      return {
        ...state,
        docusign_session: action.payload,
      };
    case SET_APPEND_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: action.payload,
      };
    case SET_KEY_PAIRS:
      return {
        ...state,
        key_pairs: action.payload,
      };
    case SET_TRUSTED_DATA:
      return {
        ...state,
        trusted_data: action.payload,
      };
    case SET_GENERATE_SIGNATURE:
      return {
        ...state,
        signature_data: action.payload,
      };
    case SET_TXT_RECORDS:
      return {
        ...state,
        txt_records: action.payload,
      };
    case SET_ALL_TEMPLATES:
      return {
        ...state,
        all_templates: action.payload,
      };
    case SET_SINGLE_TEMPLATE:
      return {
        ...state,
        template_data: action.payload,
      };
    case SET_ALL_VERIFIABLE_CREDENTIALS:
      return {
        ...state,
        all_verifiable_credentials: action.payload,
      };

    case SET_INTEGRA_LEDGER_DATA:
      return {
        ...state,
        integra_identity_data: action.payload,
      };
    case SET_SIGNATURE_DATA:
      return {
        ...state,
        docusign_signature_check: action.payload,
      };

    case SET_ORG_SIGNATURE:
      return {
        ...state,
        org_signature: action.payload,
      };
    case ADD_STEPPER_DATA:
      return {
        ...state,
        stepper_memory: { ...state.stepper_memory, ...action.payload },
      };
    case CLEAR_STEPPER_DATA:
      return {
        ...state,
        stepper_memory: null,
      };
    case SET_SERVER_DOC_META:
      return {
        ...state,
        server_metadata: action.payload,
      };
    case SET_DOC_DATA:
      return {
        ...state,
        document_id: action.payload,
      };

    case SET_INTEL_CONFIG:
      return {
        ...state,
        intel_config: action.payload,
      };

    case SET_ARCHIVED_DOCUMENTS:
      return {
        ...state,
        archived_documents: action.payload,
      };
    case META_DATA_RENDER:
      return {
        ...state,
        render_schema: action.payload,
      };
    case ALL_SCHEMA_FORMS:
      return {
        ...state,
        all_schema_forms: action.payload,
      };
    case ALL_SCHEMA_FORMS_TYPE:
      return {
        ...state,
        all_schema_forms_types: action.payload,
      };
    case SCHEMA_FORM_BY_ID:
      return {
        ...state,
        schema_forms_by_id: action.payload,
      };

    case SET_USER_SCHEMA_BY_ID:
      return {
        ...state,
        get_user_schema_by_id: action.payload.userData,
        user_schema_time_stamp: action.payload.time,
      };

    case GET_DOCUMENT_ACTIONS:
      return {
        ...state,
        get_new_document_actions: action.payload,
      };

    case SET_ACTIONS_ID:
      return {
        ...state,
        get_actions_id: action.payload,
      };

    case GET_USER_SCHEMAS:
      return {
        ...state,
        get_user_schemas: action.payload,
      };

    case MULTIPLE_SCHEMA_FORMS:
      return {
        ...state,
        multiple_schema_forms_data: action.payload,
      };

    case SET_ARCHIVED_DOCS_COUNT:
      return {
        ...state,
        archived_docs_count: action.payload,
      };
    case SET_CYCLR_TOKEN:
      return {
        ...state,
        cyclr_token: action.payload,
      };
    case SET_USER_CYCLR_MARKETPLACE:
      return {
        ...state,
        cyclr_user_marketplace_payload: action.payload,
      };
    case SET_CYCLR_MARKETPLACE:
      return {
        ...state,
        cyclr_marketplace_payload: action.payload,
      };
    case SET_NEW_CYCLR_TOKEN:
      return {
        ...state,
        generate_cyclr_token: action.payload,
      };
    case SET_PAYMENT_DATA:
      return {
        ...state,
        paymentData: action.payload,
      };

    case STORE_FRONT_PAGE_FILE:
      return {
        ...state,
        frontPageFile: action.payload,
      };
    case SET_CHAT_LOADING:
      return {
        ...state,
        is_chat_loading: action.payload,
      };
    default:
      return state;
  }
}

function ContentContextProvider({ children }) {
  const [state, dispatch] = useReducer(contentReducer, {
    contents: [],
    tokenizedContents: [],
    files: [],
    channels: [],
    // channelmessages: [],
    single_conversation: null,
    messages: [],
    transactions: [],
    applications: [],
    myapplications: [],
    singleapp: [],
    appConfig: [],
    singleAppConfig: {},
    myAppDetail: {},
    appTags: [],
    appsSearchByTags: null,
    docusign_data: {},
    isSessionLoading: false,
    docusign_authorization: false,
    envelope_data: null,
    blockhain_data: {},
    demo_data: [],
    single_demo_data: {},
    app_defaults: [],
    single_dynamic_form: [],
    doc_data: null,
    org_data: null,
    all_conversations: [],
    docusign_session: null,
    notifications: [],
    trusted_data: null,
    signature_data: null,
    all_templates: [],
    template_data: null,
    all_verifiable_credentials: [],
    integra_identity_data: null,
    org_signature: null,
    extractedmetaData: {},
    stepper_memory: null,
    server_metadata: null,
    document_id: null,
    intel_config: null,
    archived_documents: null,
    cyclr_token: null,
    generate_cyclr_token: null,
    paymentData: null,
    totalCount: null,
    tokenizedCount: null,
    archived_docs_count: null,
    is_chat_loading: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: GET_MY_APP_SEARCH_BY_TAG,
      payload: null,
    });
    dispatch({
      type: GET_DOC_DATA,
      payload: null,
    });

    dispatch({
      type: SET_DOCUSIGN_SESSION,
      payload: false,
    });
    dispatch({
      type: SET_SINGLE_TEMPLATE,
      payload: null,
    });
    dispatch({
      type: CLEAR_STEPPER_DATA,
    });
    dispatch({
      type: SET_ARCHIVED_DOCUMENTS,
      payload: null,
    });
    dispatch({
      type: META_DATA_RENDER,
      payload: null,
    });

    dispatch({
      type: SET_ARCHIVED_DOCS_COUNT,
      payload: null,
    });
    dispatch({
      type: SET_CONTENTS,
      payload: null,
    });
    dispatch({
      type: SET_TOTAL_COUNT,
      payload: null,
    });
    dispatch({
      type: SET_PAGE_COUNT,
      payload: null,
    });
    dispatch({
      type: SET_TOKENIZED_COUNT,
      payload: null,
    });
    dispatch({
      type: SET_INTEL_CONFIG,
      payload: null,
    });
    dispatch({
      type: SET_CYCLR_TOKEN,
      payload: null,
    });
    dispatch({
      type: SET_CYCLR_MARKETPLACE,
      payload: null,
    });
    dispatch({
      type: SET_NEW_CYCLR_TOKEN,
      payload: null,
    });
    dispatch({
      type: SET_PAYMENT_DATA,
      payload: null,
    });

    dispatch({
      type: SET_CONVERSATION_DATA,
      payload: [],
    });
    dispatch({
      type: SET_SINGLE_CONVERSATION,
      payload: null,
    });
    dispatch({
      type: SET_MESSAGES,
      payload: [],
    });
  }, [navigate]);

  const fetch_transactions = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.bctrans);
      dispatch({
        type: SET_TRANSACTIONS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  const fetch_applications = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.getapps);
      dispatch({
        type: SET_APPLICATIONS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch applications:', error);
    }
  }, []);

  const fetch_myapplications = useCallback(async () => {
    dispatch({
      type: GET_MY_APP_DETAIL,
      payload: {},
    });
    try {
      const response = await auth_axios.get(authendpoints.authnew.myapps);
      dispatch({
        type: SET_MYAPPLICATIONS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MY_APP_DETAIL,
        payload: {},
      });
      console.error('Failed to fetch my applications:', error);
    }
  }, []);

  const get_app_by_id = useCallback(async (appId) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.getapps}/${appId}`);
      dispatch({
        type: SET_APP,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch single applications:', error);
    }
  }, []);

  const processData = (data) =>
    data.map((item) =>
      item.document_type.startsWith('Imported')
        ? {
            ...item,
            integra_id: item.metadata.integra_id ? item.metadata.integra_id : item.integra_id,
          }
        : item
    );

  const fetch_content = useCallback(async (rowsPerPage, pageSize) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_documents}?page=${pageSize}&size=${rowsPerPage}`
      );
      const processedData = processData(response.data.data); // Process data before dispatching
      const totalCount = response?.data?.totalCount;
      const lastPage = response?.data?.lastPage;

      dispatch({
        type: SET_CONTENTS,
        payload: processedData,
      });
      dispatch({
        type: SET_TOTAL_COUNT,
        payload: totalCount,
      });
      dispatch({
        type: SET_PAGE_COUNT,
        payload: lastPage,
      });
    } catch (error) {
      console.error('Failed to fetch content:', error);
    }
  }, []);

  const import_doc = useCallback(async (postBody) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.document, postBody);
      enqueueSnackbar('Successfully imported document', { variant: 'success' });
      return response?.data;
    } catch (error) {
      console.error('*************Failed to post content:', error);
      enqueueSnackbar(error?.message, { variant: 'error' });
      return null;
    }
  }, []);

  const post_myapplication = useCallback(async (postBody) => {
    try {
      await auth_axios.post(authendpoints.authnew.myapps, postBody);
      fetch_myapplications();
      enqueueSnackbar(messages.postMyAppSuccess, { variant: 'success' });
      // return true;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.error('*************Failed to post content:', error);
      // return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post_docusign_doc = useCallback(async (postBody) => {
    try {
      await auth_axios.post(authendpoints.authnew.add_docusign_document, postBody);
      enqueueSnackbar(messages.docUploadSuccessMsg, { variant: 'success' });
    } catch (error) {
      console.error('*************Failed to post content:', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post_update_docusign_doc = useCallback(async (postBody) => {
    try {
      await auth_axios.post(authendpoints.authnew.update_docusign_document, postBody);
    } catch (error) {
      console.error('*************Failed to post content:', error);
    }
  }, []);

  const fetch_channels = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.channel);
      dispatch({
        type: SET_CHANNELS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch content:', error);
    }
  }, []);

  const post_channel = useCallback(async (channelBody) => {
    try {
      const response = await auth_axios.post(
        'https://xsqy-f6go-huo7.n7c.xano.io/api:lkmcgxf_/channel',
        channelBody
      );
    } catch (error) {
      console.error('Failed to post channel:', error);
    }
  }, []);

  const post_document_no_register = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.document_no_register, payload);
      enqueueSnackbar(messages.docUploadNoRegSuccessMsg, { variant: 'success' });

      return response;
    } catch (error) {
      console.error('Failed to post document:', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return false;
    }
  }, []);
  const docusign_document_no_register = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.docusign_register, payload);
      enqueueSnackbar(messages.docUploadNoRegSuccessMsg, { variant: 'success' });

      return response;
    } catch (error) {
      console.error('Failed to post document:', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return false;
    }
  }, []);

  const post_message = useCallback(async (payload, guid) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.messages, payload);
      if (response && guid) {
        document_conversations();
        // fetch_messages(guid);
      }
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_single_app_config = useCallback(async (appId) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.app_config_record}/${appId}`);
      dispatch({
        type: GET_SINGLE_APP_CONFIG,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_APP_CONFIG,
        payload: {},
      });
      console.error('Failed to fetch single applications:', error);
    }
  }, []);

  const getDocumentById = useCallback(
    (documentId) => {
      const document = state.contents.find((doc) => doc.id === documentId);
      return document;
    },
    [state.contents]
  );

  const getApplicationById = useCallback(
    (appId) => {
      const singleApplication = state.applications.find((app) => app.id === appId);
      return singleApplication;
    },
    [state.applications]
  );

  const remove_myapplications = useCallback(
    async (payload) => {
      // const {delPayload,id}=payload;
      try {
        // const response = await auth_axios.delete(`${authendpoints.authnew.myapps}${payload}`);
        const response = await auth_axios.delete(
          `${authendpoints.authnew.myapps}/${payload?.id}`,
          payload?.delPayload
        );
        fetch_myapplications();
        enqueueSnackbar(messages.delMyAppSuccess, { variant: 'success' });
      } catch (error) {
        console.error('Failed to remove single application:', error);
        enqueueSnackbar(error.message, { variant: 'warning' });
      }
    },
    [fetch_myapplications]
  );

  const remove_app_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(
        `${authendpoints.authnew.app_config_record}/${payload}`,
        { data: { myapplication_id: payload } }
      );
    } catch (error) {
      console.error('Failed to remove single application:', error);
    }
  }, []);

  const add_app_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.app_config_record}`, payload);
      // get_single_app_config(payload.myapplications_id);
      localStorage.removeItem('docusign_access_token');
      return response;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }, []);

  const update_app_config = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.app_config_record}/${id}`,
        payload
      );
      // get_single_app_config(payload.myapplications_id);
      localStorage.removeItem('docusign_access_token');
      return response;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }, []);

  const update_app_config_auth_info = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.app_config_record}/update_config`,
        payload
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  const get_my_app_detail = useCallback(async (appId) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.getmyapp}/${appId}/combined-data`
      );
      dispatch({
        type: GET_MY_APP_DETAIL,
        payload: response.data,
      });
      await checkDocusignSession();
      const storedData = localStorage.getItem(DOCUSIGN_KEY) ?? sessionStorage.getItem(DOCUSIGN_KEY);
      const extractedData = storedData && JSON.parse(storedData);

      if (storedData) {
        dispatch({
          type: GET_MY_APP_DETAIL,
          payload: response.data,
        });
        dispatch({
          type: DOCUSIGN_SESSION,
          payload: extractedData,
        });
      } else if (
        // * Step 1 - Call OAuth for Docusign token
        !storedData &&
        window.location.href.includes('#') &&
        window.location.href.includes('access_token=')
      ) {
        const hashFragment = window.location.href.split('#')[1];
        const keyValuePairs = hashFragment.split('&');

        // Create an object to store the extracted data
        const data = {};

        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split('=');
          data[key] = value;
        });
        // * Step 2 - Call Docusign UserInfo API after Step 1 success
        // Check if the required keys are present and update myAppDetail
        if (data.access_token && data.expires_in && data.token_type && data.scope) {
          const headers = {
            Authorization: `Bearer ${data.access_token}`,
          };
          axios
            .get(docusignConfig.docuSign_userInfo_URI, { headers })
            .then((newResponse) => {
              // Handle the response data here
              const docusign_user_info = newResponse.data;

              // * Docusign session handler
              const currentTime = new Date();
              const expiryTime = new Date(currentTime.getTime() + Number(data.expires_in) * 1000);

              const docuSignRemodeledData = {
                docusign_auth: {
                  access_token: data.access_token,
                  expires_in: Number(data.expires_in),
                  token_type: data.token_type,
                  scope: data.scope,
                  start_time: currentTime.toISOString(),
                  expiry_time: expiryTime.toISOString(),
                },
                docusign_user_info,
              };
              localStorage.setItem(DOCUSIGN_KEY, JSON.stringify(docuSignRemodeledData));

              dispatch({
                type: DOCUSIGN_SESSION,
                payload: docuSignRemodeledData,
              });
              dispatch({
                type: GET_MY_APP_DETAIL,
                payload: {
                  ...response.data,
                  docuSignAPI: true,
                },
              });

              // const payload = {
              //   myapplications_id: Number(appId),
              //   access_token: data.access_token,
              //   token_expiration: expiryTime.toISOString(),
              // };
              // update_app_config_auth_info(payload);

              // * Step 3: Call eSignature REST API after Docusign UserInfo API
              if (newResponse && newResponse.data) {
                axios
                  .get(
                    `${docusign_user_info.accounts[0].base_uri}/${docusignConfig.docuSign_eSignature_URI}${docusign_user_info.accounts[0].account_id}`,
                    {
                      headers,
                    }
                  )
                  .then((neweSignatureResponse) => {})
                  .catch((error) => {
                    // Handle any errors here
                    console.error('Error:', error);
                  });
              }
            })
            .catch((error) => {
              // Handle any errors here
              console.error('Error:', error);
            });
        } else {
          dispatch({
            type: GET_MY_APP_DETAIL,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_MY_APP_DETAIL,
        payload: {},
      });
      console.error('Failed to fetch single applications:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transfer_docusign_doc = useCallback(async (postBody) => {
    try {
      const response = await auth_axios.post(
        authendpoints.authnew.transfer_docusign_document,
        postBody
      );
      enqueueSnackbar(messages.docTransferAPISuccessMsg, { variant: 'success' }); // ! For some reason, this is not working properly
      return true; // * Use this boolean to parent component to handle success messages
    } catch (error) {
      enqueueSnackbar(messages.docTransferAPIErrorMsg, { variant: 'error' }); // ! For some reason, this is not working properly
      return false; // * Use this boolean to parent component to handle error messages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_docusign_auth = useCallback(async (id) => {
    try {
      const response = await auth_axios.get(
        `https://testnetwork.integraledger.com/api:lkmcgxf_/myapplications/get_docusign_auth_by_document_id/${id}`
      );
      dispatch({
        type: GET_DOCUSIGN_AUTH,
        payload: response.data,
      });
      enqueueSnackbar(messages.docTransferAPISuccessMsg, { variant: 'success' }); // ! For some reason, this is not working properly
      return true; // * Use this boolean to parent component to handle success messages
    } catch (error) {
      enqueueSnackbar(messages.docTransferAPIErrorMsg, { variant: 'error' }); // ! For some reason, this is not working properly
      dispatch({
        type: GET_DOCUSIGN_AUTH,
        payload: {},
      });
      return false; // * Use this boolean to parent component to handle error messages
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_app_tags = useCallback(async () => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.app_tags}`);
      dispatch({
        type: GET_APP_TAGS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch applications tags', error);
    }
  }, []);

  const get_actions = useCallback(async () => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.documentActions}`);
      dispatch({
        type: GET_ACTIONS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch applications tags', error);
    }
  }, []);

  const get_my_application_id_by_guid = useCallback(async (app_guid) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.myapp_by_guid}/${app_guid}`);

      return response.data;
    } catch (error) {
      console.error('Failed to fetch applications tags', error);

      return error;
    }
  }, []);
  const get_my_application_search_by_tag = useCallback(async (postBody) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.myapps}${authendpoints.authnew.get_my_application_search_by_tag}?app_tag=${postBody}`
        // { params: postBody }
        // {
        //   payload: { app_tag: [`${postBody}`] },
        // }
      );
      dispatch({
        type: GET_MY_APP_SEARCH_BY_TAG,
        payload: response.data,
      });
      return response?.data;
    } catch (error) {
      console.error('Failed to fetch applications search by tags', error);
      return error;
    }
  }, []);

  const check_docusign_session = useCallback(async (id) => {
    const docuSign_access_token = localStorage.getItem('docusign_access_token');
    dispatch({
      type: SET_DOCUSIGN_LOADER,
      payload: true,
    });
    if (docuSign_access_token) {
      try {
        const loginResponse = await auth_axios.get(
          `${authendpoints.authnew.docusign_auth}?docusign_token=${docuSign_access_token}`
        );
        dispatch({
          type: SET_DOCUSIGN_DATA,
          payload: loginResponse?.data,
        });
        dispatch({
          type: SET_DOCUSIGN_SESSION,
          payload: !!loginResponse?.data,
        });
        dispatch({
          type: SET_DOCUSIGN_LOADER,
          payload: false,
        });
      } catch (error) {
        if (error.statusCode === 401 || error?.error) {
          await login_docusign_jwt_grant(id);
          // enqueueSnackbar(`Session Timeout, Please login to DocuSign`, {
          //   variant: 'warning',
          // });
        } else {
          enqueueSnackbar(error?.message ?? error, {
            variant: 'warning',
          });
          dispatch({
            type: SET_DOCUSIGN_SESSION,
            payload: false,
          });
          dispatch({
            type: SET_DOCUSIGN_LOADER,
            payload: false,
          });
        }
      }
    } else {
      login_docusign_jwt_grant(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login_docusign_jwt_grant = useCallback(async (id) => {
    dispatch({
      type: SET_DOCUSIGN_LOADER,
      payload: true,
    });
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.docusign_token}?app_guid=${id}`
      );
      if (response) {
        localStorage.setItem('docusign_access_token', response?.data);
        // * New Change to DocuSign Session Login
        const loginResponse = await auth_axios.get(
          `${authendpoints.authnew.docusign_auth}?docusign_token=${response.data}`
        );
        dispatch({
          type: SET_DOCUSIGN_DATA,
          payload: loginResponse?.data,
        });

        dispatch({
          type: SET_DOCUSIGN_SESSION,
          payload: !!loginResponse?.data,
        });
        dispatch({
          type: SET_DOCUSIGN_LOADER,
          payload: false,
        });
        enqueueSnackbar(
          `Hi! 👋 ${loginResponse?.data?.userInfo?.name},  Welcome back to DocuSign.`,
          {
            variant: 'success',
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      console.error('Failed in login_docusign_jwt_grant', error);
      dispatch({
        type: SET_DOCUSIGN_SESSION,
        payload: false,
      });
      dispatch({
        type: SET_DOCUSIGN_LOADER,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add_new_application = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.getapps, payload);

      enqueueSnackbar(messages.AddNewAppSucess, { variant: 'success' });
      navigate(paths.dashboard.apps.root);
      // return true;
    } catch (error) {
      console.error('Failed to Add new Application', error);
      enqueueSnackbar(error.message, { variant: 'error' });
      // return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-shadow
  const update_application = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.patch(`${authendpoints.authnew.getapps}/${id}`, payload);

      enqueueSnackbar(messages.UpdateAppSucess, { variant: 'success' });
      navigate(paths.dashboard.apps.root);
      // return true;
    } catch (error) {
      console.error('Failed to update Application', error);
      enqueueSnackbar(error.message, { variant: 'error' });
      // return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remove_application = useCallback(async (payload) => {
    try {
      await auth_axios.delete(`${authendpoints.authnew.getapps}/${payload}`);
      enqueueSnackbar(messages.RemoveAppSucess, { variant: 'success' });
      navigate(paths.dashboard.apps.root);
    } catch (error) {
      console.error('Failed to remove single application:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const docusign_envelope = useCallback(async (account_id, token, payload) => {
    // const remakePayload = JSON.stringify(payload);
    try {
      const response = await auth_axios.post(authendpoints.authnew.docusign_envelope, {
        account_id,
        token,
        data: payload,
      });

      dispatch({
        type: SET_ENVELOPE_DATA,
        payload: response?.data,
      });
      return response?.data;

      // enqueueSnackbar(messages.RemoveAppSucess, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.errorCode ?? error, { variant: 'error' });
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const docusign_sending_cermony_link = useCallback(
    async (account_id, token, payload, envelope_id) => {
      // const remakePayload = JSON.stringify(payload);
      try {
        const response = await auth_axios.post(
          authendpoints.authnew.docusign_sending_cermony_link,
          {
            account_id,
            token,
            envelope_id,
            data: payload,
          }
        );

        dispatch({
          type: SET_ENVELOPE_DATA,
          payload: response?.data,
        });
        return response?.data;

        // enqueueSnackbar(messages.RemoveAppSucess, { variant: 'success' });
      } catch (error) {
        console.error('Error', error);
        enqueueSnackbar(error?.errorCode ?? error, { variant: 'error' });
        return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const docusign_envelope_recipient = useCallback(
    async (account_id, token, envelope_id, payload) => {
      try {
        const response = await auth_axios.post(authendpoints.authnew.docusign_envelope_recipient, {
          account_id,
          token,
          envelope_id,
          data: payload,
        });

        return response?.data;
      } catch (error) {
        console.error('Error', error);
        enqueueSnackbar(error?.message ?? error, { variant: 'error' });
        return error;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );
  const verify_digest_signature = useCallback(async (PublicKey, Signature, Digest) => {
    const payload = {
      PublicKey,
      Signature,
      Digest,
    };
    try {
      const response = await auth_axios.post(
        authendpoints.authnew.verify_digest_signature,
        payload
      );
      // eslint-disable-next-line eqeqeq
      const isValid = response?.data?.status == 200;
      if (!isValid) {
        const errorMessage = response?.data?.message;
        enqueueSnackbar(errorMessage, { variant: 'warning' });
      }

      return isValid;
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const identity_exist_by_integraId = useCallback(async (payload, networkType) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_explorer_url}${payload}${
          networkType ? `?net=${networkType}` : ''
        }`
      );
      dispatch({
        type: SET_INTEGRA_LEDGER_DATA,
        payload: response?.data?.data,
      });
    } catch (error) {
      console.error('Error', error);
      throw new Error(error);
      // enqueueSnackbar('No registration exists on Integra Ledger', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const identity_exist_by_documentHash = useCallback(async (payload, networkType) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.identity_exist_by_document_hash}${payload}${
          networkType ? `?net=${networkType}` : ''
        }`
      );
      dispatch({
        type: SET_INTEGRA_LEDGER_DATA,
        payload: response?.data?.data,
      });
      return response?.data;
    } catch (error) {
      console.error('Error', error);
      return null;
      // enqueueSnackbar(error?.message ?? error, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_explorer_url = useCallback(async (payload, network) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_explorer_url}${payload}${network ? `?net=${network}` : ''}`
      );
      dispatch({
        type: SET_BLOCKCHAIN_DATA,
        payload: response?.data?.data,
      });
      return response?.data?.data;
    } catch (error) {
      console.error('Error', error);
      dispatch({
        type: SET_BLOCKCHAIN_DATA,
        payload: {},
      });
      // enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const delete_doc = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(authendpoints.authnew.document, {
        data: { ids: payload },
      });
      fetch_content();
      enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const set_default_application = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.defaultapplication, payload);
      return response?.data;
      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_all_demo = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.defaultsettings);
      dispatch({
        type: GET_ALL_DEMO_DATA,
        payload: response?.data,
      });

      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_demo = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.defaultsettings}${payload}`);
      dispatch({
        type: GET_SINGLE_DEMO_DATA,
        payload: response?.data,
      });
      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      // return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const add_demo = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.defaultsettings, payload);
      navigate(paths.dashboard.demo.root);
      window.location.reload();

      enqueueSnackbar('New Demo created successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      // return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const update_demo = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.put(
        `${authendpoints.authnew.defaultsettings}${id}`,
        payload
      );
      navigate(paths.dashboard.demo.root);

      window.location.reload();

      // return response?.data;
      enqueueSnackbar('Demo updated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      // return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const delete_demo = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(
        `${authendpoints.authnew.defaultsettings}${payload}`
      );
      await get_all_demo();
      return true;
      // return response?.data;
      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggle_demo = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(`${authendpoints.authnew.defaultsettings}${payload}`);
      await get_all_demo();
      if (response?.data?.is_current) {
        window.location.reload();
        return true;
      }
      return false;

      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reset_demo = useCallback(async () => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.resetdefaultsettings);
      await get_all_demo();
      window.location.reload();
      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_app_defaults = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.default_apps);
      dispatch({
        type: SET_APP_DEFAULTS_DATA,
        payload: response?.data,
      });

      // enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_default_apps = useCallback(async (payload) => {
    try {
      const response = await auth_axios.put(authendpoints.authnew.update_default_apps, payload);
      await get_app_defaults();
      enqueueSnackbar('Default apps updated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reset_default_apps = useCallback(async () => {
    try {
      const response = await auth_axios.delete(authendpoints.authnew.reset_default_apps);
      await get_app_defaults();
      enqueueSnackbar('Default apps reset successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const add_dynamic_forms = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.dynamicform, payload);

      enqueueSnackbar('Dynamic form created successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_dynamic_forms = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.dynamicform);
      dispatch({
        type: GET_DYNAMIC_FORM,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch_doc_data = useCallback(async (id) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.docusign_document}/${id}`);
      dispatch({
        type: GET_DOC_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
  }, []);

  const get_org_details = useCallback(async (id) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.organization_details_by_guid}/${id}`
      );
      dispatch({
        type: GET_ORG_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
  }, []);

  const establish_connection = useCallback(async (payload, disableNavigation) => {
    if (payload.base_url === '') {
      enqueueSnackbar('No base url', {
        variant: 'error',
      });
      return false;
    }

    try {
      const response = await auth_axios.post(authendpoints.authnew.document_users, payload);
      // enqueueSnackbar('Connection established successfully', {
      //   variant: 'success',
      // });

      if (!disableNavigation) {
        setTimeout(() => {
          navigate(`${paths.dashboard.chat}?guid=${response?.data?.document_conversation_guid}`);
        }, 2000);
      }
      return response?.data;
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const document_conversations = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.document_conversations);
      dispatch({
        type: SET_CONVERSATION_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const is_document_conversations = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.document_conversations}/${payload}`
      );

      // dispatch({
      //   type: SET_CONVERSATION_DATA,
      //   payload: response.data,
      // });
      return response?.data;
    } catch (error) {
      console.error('Error', error);
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'warning',
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_conversations = useCallback(async (payload, guid) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.document_conversations}/${guid}`,
        payload
      );
      document_conversations();
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const update_conversation_status = useCallback(async (payload, guid) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.document_conversations}/${guid}/status/${payload}`
      );
      await document_conversations();
      fetch_messages(guid);
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch_messages = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.document_conversations}/${payload}`
      );
      if (response) {
        dispatch({
          type: SET_SINGLE_CONVERSATION,
          payload: response.data,
        });
        dispatch({
          type: SET_MESSAGES,
          payload: response?.data?.messages,
        });
      }
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post_message_file = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.messages_file, payload);
      if (response && response?.data?.filename) {
        return response?.data?.filename;
      }
      return '';
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      throw new Error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post_docusign_doc_v2 = useCallback(async (postBody) => {
    try {
      await auth_axios.post(authendpoints.authnew.add_docusign_document_v2, postBody);
      // await auth_axios.post(authendpoints.authnew.document, postBody);
      enqueueSnackbar(messages.docUploadSuccessMsgv2, { variant: 'success' });
      return true;
    } catch (error) {
      console.error('*************Failed to post content:', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'warning',
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const append_messages = useCallback(async (message) => {
    const matchingConversation = state.all_conversations.find(
      (conversation) => conversation.id === message?.document_conversations_id
    );
    // if (message?.document_conversations_id === state.messages[0]?.document_conversations_id) {
    //   dispatch({
    //     type: SET_APPEND_MESSAGES,
    //     payload: message,
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_notifications = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.notifications, {
        params: {
          is_read: payload?.is_read,
        },
      });

      dispatch({
        type: SET_NOTIFICATIONS,
        payload: response?.data?.notifications,
        count: response?.data?.count,
      });
      dispatch({
        type: SET_NOTIFICATIONS_COUNT,
        payload: response?.data?.count,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const append_notifications = useCallback(async (message) => {
    dispatch({
      type: APPEND_NOTIFICATIONS,
      payload: message,
    });
  }, []);

  const generate_key = useCallback(async () => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.generate_key);

      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return null;
    }
  }, []);

  const check_org_trusted = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.organizations_trusted}/${payload}`
      );

      dispatch({
        type: SET_TRUSTED_DATA,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return null;
    }
  }, []);
  const generate_signature = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.generate_signature}/${payload}`
      );
      dispatch({
        type: SET_GENERATE_SIGNATURE,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return null;
    }
  }, []);
  const get_txt_records = useCallback(async (payload) => {
    const modifiedPayload = payload.replace(/^(https?:\/\/)?/, '');
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_txt_records}/${modifiedPayload}`
      );
      dispatch({
        type: SET_TXT_RECORDS,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'error',
      // });
      return null;
    }
  }, []);
  const verify_dns_signature = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.verify_signature, payload);

      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return null;
    }
  }, []);
  const get_all_templates = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.templates);
      dispatch({
        type: SET_ALL_TEMPLATES,
        payload: response?.data,
      });
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
  }, []);
  const get_template_byID = useCallback(async (id) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.templates}/${id}`);
      dispatch({
        type: SET_SINGLE_TEMPLATE,
        payload: response?.data,
      });
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
  }, []);
  const update_template = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.templates, payload);
      navigate(paths.dashboard.organization.templates);
      // await get_template_byID(id);
      enqueueSnackbar(response?.data?.message ?? 'Template updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const send_verification_code = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.send_verification_code, payload);
      if (payload?.is_email) {
        navigate(`${paths.dashboard.user.verify}?type=email`);
        enqueueSnackbar(
          response?.data?.message ?? 'A verification code has been sent to your email',
          {
            variant: 'success',
          }
        );
      } else {
        navigate(`${paths.dashboard.user.verify}?type=phone_number`);
        enqueueSnackbar(
          response?.data?.message ?? 'A verification code has been sent to your phone number',
          {
            variant: 'success',
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_all_verifiable_credentials = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.verifiable_credentials);
      dispatch({
        type: SET_ALL_VERIFIABLE_CREDENTIALS,
        payload: response?.data,
      });
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const generate_verifiable_credentials = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.verifiable_credentials, payload);
      enqueueSnackbar('Verfiable Credential generated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generate_org_signature = useCallback(
    async (comm_endpoint, organization_guid, integra_id) => {
      try {
        const response = await auth_axios.get(
          `${comm_endpoint}/organization/generate-signature/${organization_guid}/integra-id/${integra_id}`
        );
        dispatch({
          type: SET_ORG_SIGNATURE,
          payload: response?.data,
        });
        return response?.data;
      } catch (error) {
        // enqueueSnackbar(
        //   Array.isArray(error?.message) ? error?.message[0] : error?.message || error,
        //   {
        //     variant: 'error',
        //   }
        // );
        return null;
      }
    },
    []
  );

  const verify_org_signature = useCallback(async (comm_endpoint, payload) => {
    try {
      const response = await auth_axios.post(
        `${comm_endpoint}/organization/verify-signature-by-integraid`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const find_doc_by_integra_id = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.find_doc_by_integra_id}/${payload}`
      );
      return response?.data;
    } catch (error) {
      return null;
    }
  }, []);

  const check_doc_by_integra_id = useCallback(async (payload, editedBaseURL) => {
    try {
      if (editedBaseURL) {
        const response = await auth_axios.get(
          `${editedBaseURL}${authendpoints.authnew.check_doc_by_integra_id}/${payload}`
        );
        return response?.data;
      }

      const response = await auth_axios.get(
        `${authendpoints.authnew.check_doc_by_integra_id}/${payload}`
      );
      return response?.data;
    } catch (error) {
      return null;
    }
  }, []);

  const delete_notifications = useCallback(async () => {
    try {
      const response = await auth_axios.delete(authendpoints.authnew.delete_notifications);

      enqueueSnackbar(response?.data?.message, { variant: 'success' });
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(error?.message ?? error, { variant: 'warning' });
    }
  }, []);

  const read_notifications = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.read_notifications, payload);
      await get_notifications();
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const post_decrypt_comm_endpoint = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.decrypt_comm_endpoint, payload);
      // enqueueSnackbar('Verfiable Credential generated successfully', {
      //   variant: 'success',
      // });
      return response?.data;
    } catch (error) {
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const read_chat = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.read_chat, payload);
      await document_conversations();
    } catch (error) {
      console.error('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_tokenized_data = useCallback(async (rowsPerPage, pageSize) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.tokenized_owner}?page=${pageSize}&size=${rowsPerPage}`
      );
      const lastPage = response?.data?.lastPage;
      dispatch({
        type: TOKENIZED_CONTENTS,
        payload: response?.data?.data,
      });
      dispatch({
        type: SET_TOKENIZED_COUNT,
        payload: response?.data?.totalCount,
      });
      dispatch({
        type: SET_PAGE_COUNT,
        payload: lastPage,
      });
      // dispatch({
      //   type: SET_TOTAL_COUNT,
      //   payload: totalCount,
      // });
    } catch (error) {
      console.error('Failed to fetch content:', error);
    }
  }, []);

  const patch_message = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.messages, payload);
      if (response) {
        // fetch_messages(guid);
        document_conversations();
      }
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_transfer_ownership = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.transfer_owner}/${payload}`);
      return response?.data;
    } catch (error) {
      return null;
    }
  }, []);
  // eslint-disable-next-line consistent-return
  const verify_verifiable_credentials = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        authendpoints.authnew.verify_verifiable_credentials,
        payload
      );
      return response?.data;
    } catch (error) {
      console.error('Error', error);

      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add_stepper_memory = useCallback(async (payload) => {
    try {
      dispatch({
        type: ADD_STEPPER_DATA,
        payload,
      });
    } catch (error) {
      console.log('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clear_stepper_memory = useCallback(async () => {
    try {
      dispatch({
        type: CLEAR_STEPPER_DATA,
      });
    } catch (error) {
      console.log('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_RSA_keys = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.document}/${payload}/keys`);
      return response?.data;
    } catch (error) {
      console.log('Error', error);
      throw new Error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_Identity_from_Blockchain = useCallback(async (sha256hash, sha384hash, getDoc) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.document}/${sha256hash}/document-hash-key/${sha384hash}${
          getDoc ? `?document=true` : ''
        }`
      );
      dispatch({
        type: SET_INTEGRA_LEDGER_DATA,
        payload: response?.data?.data?.blockchainData,
      });
      dispatch({
        type: SET_DOC_DATA,
        payload: response?.data?.data?.document,
      });
      if (getDoc && response?.data?.data?.document) {
        dispatch({
          type: SET_SERVER_DOC_META,
          payload: response?.data?.data?.document?.metadata,
        });
      }
    } catch (error) {
      console.log('Error', error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_signature_validation = useCallback(async (payload) => {
    try {
      // const response = await auth_axios.post(
      //   `${CHILKAT_ENDPOINT}/${authendpoints.authnew.get_signature_validation}`,
      //   payload
      // );

      const response = await auth_axios.post(CHILKAT_ENDPOINT, payload);
      if (response) {
        dispatch({
          type: SET_SIGNATURE_DATA,
          payload: response?.data?.data,
        });
        return {
          isValidSignature: response?.data?.data?.signatureDetails[0]?.validated,
          signatureName256Hash: response?.data?.data?.signatureName256Hash,
          signatureName384Hash: response?.data?.data?.signatureName384Hash,
        };
      }

      return {
        isValidSignature: false,
        signatureName256Hash: null,
        signatureName384Hash: null,
      };
    } catch (error) {
      console.log('Error', error);
      return {
        isValidSignature: false,
        signatureName256Hash: null,
        signatureName384Hash: null,
      };
      // throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add_intel_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.intel_config}`, payload);

      localStorage.removeItem('docusign_access_token');
      await get_intel_config(payload?.type);
      enqueueSnackbar(messages.configAddedMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pristamtic_auth_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.prismatic_token}`, payload);

      // localStorage.removeItem('docusign_access_token');
      // await get_intel_config(payload?.type);
      return response.data.token;
      // enqueueSnackbar(messages.configAddedMsg, {
      //   variant: 'success',
      // });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cyclr_auth_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.cyclr_token}`, payload);
      dispatch({
        type: SET_CYCLR_TOKEN,
        payload: response?.data.Token,
      });
      const dateTime = {
        token: response?.data?.Token,
        expirytime: response?.data?.ExpiresAtUtc,
      };
      localStorage.setItem('cyclr_auth_token', JSON.stringify(dateTime));
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user_cyclr_marketplace = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.cyclr_user_marketplace_token}`,
        payload
      );
      dispatch({
        type: SET_USER_CYCLR_MARKETPLACE,
        payload: response?.data?.MarketplaceUrl,
      });
      // const dateTime = {
      //   token: response?.data?.Token,
      //   expirytime: response?.data?.ExpiresAtUtc,
      // };
      // localStorage.setItem('cyclr_auth_token', JSON.stringify(dateTime));
      return response?.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cyclr_marketplace = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.cyclr_marketplace_token}`,
        payload
      );
      dispatch({
        type: SET_CYCLR_MARKETPLACE,
        payload: response?.data?.MarketplaceUrl,
      });
      // const dateTime = {
      //   token: response?.data?.Token,
      //   expirytime: response?.data?.ExpiresAtUtc,
      // };
      // localStorage.setItem('cyclr_auth_token', JSON.stringify(dateTime));
      return response?.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generate_cyclr_auth_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.generate_cyclr_token}`,
        payload
      );
      dispatch({
        type: SET_NEW_CYCLR_TOKEN,
        payload: response?.data?.access_token,
      });
      const dateTime = {
        token: response?.data?.access_token,
        expiryTime: response?.data['.expires'],
        issueDate: response?.data['.issued'],
      };
      localStorage.setItem('generate_cyclr_auth_token', JSON.stringify(dateTime));

      return response?.data?.access_token;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const integration_auth_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.integration_token}`, payload);

      // localStorage.removeItem('docusign_access_token');
      // await get_intel_config(payload?.type);
      return response.data;
      // enqueueSnackbar(messages.configAddedMsg, {
      //   variant: 'success',
      // });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_intel_config = useCallback(async (payload, id) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.intel_config}/${id}`,
        payload
      );

      localStorage.removeItem('docusign_access_token');
      await get_intel_config(payload?.type);
      enqueueSnackbar(messages.configUpdateMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const get_intel_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.intel_config}?type=${payload ?? 1}`
      );
      dispatch({
        type: SET_INTEL_CONFIG,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      console.error(error);
      return null;
      // throw new Error(error);
    }
  }, []);

  const checkAccountExists = (accounts, configResponse) =>
    accounts.find((account) => account?.account_id === configResponse?.docusign_account_id) || null;

  const implicit_grant_session = useCallback(async (configType) => {
    try {
      dispatch({
        type: SET_DOCUSIGN_LOADER,
        payload: true,
      });
      await checkDocusignSession();
      const storedData = localStorage.getItem(DOCUSIGN_KEY) ?? sessionStorage.getItem(DOCUSIGN_KEY);
      const extractedData = storedData && JSON.parse(storedData);
      if (storedData) {
        dispatch({
          type: DOCUSIGN_SESSION,
          payload: extractedData,
        });
        dispatch({
          type: SET_DOCUSIGN_LOADER,
          payload: false,
        });
      } else if (
        // * Step 1 - Call OAuth for Docusign token
        !storedData &&
        window.location.href.includes('#') &&
        window.location.href.includes('access_token=')
      ) {
        const hashFragment = window.location.href.split('#')[1];
        const keyValuePairs = hashFragment.split('&');

        // Create an object to store the extracted data
        const data = {};

        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split('=');
          data[key] = value;
        });
        // * Step 2 - Call Docusign UserInfo API after Step 1 success
        // Check if the required keys are present and update myAppDetail
        if (data.access_token && data.expires_in && data.token_type && data.scope) {
          const headers = {
            Authorization: `Bearer ${data.access_token}`,
          };
          axios
            .get(docusignConfig.docuSign_userInfo_URI, { headers })
            .then(async (newResponse) => {
              // Handle the response data here
              const docusign_user_info = newResponse.data;

              // * Docusign session handler
              const currentTime = new Date();
              const expiryTime = new Date(currentTime.getTime() + Number(data.expires_in) * 1000);

              const docuSignRemodeledData = {
                docusign_auth: {
                  access_token: data.access_token,
                  expires_in: Number(data.expires_in),
                  token_type: data.token_type,
                  scope: data.scope,
                  start_time: currentTime.toISOString(),
                  expiry_time: expiryTime.toISOString(),
                },
                docusign_user_info,
              };

              // * Step 3: Call eSignature REST API after Docusign UserInfo API
              const configResponse = await get_intel_config(configType);

              if (newResponse && newResponse.data) {
                const userAccount = checkAccountExists(docusign_user_info.accounts, configResponse);
                if (userAccount !== null) {
                  axios
                    .get(
                      `${userAccount.base_uri}/${docusignConfig.docuSign_eSignature_URI}${userAccount.account_id}`,
                      {
                        headers,
                      }
                    )
                    .then(async (neweSignatureResponse) => {
                      const updatedDocuSignRemodeledData = {
                        ...docuSignRemodeledData,
                        isValidOrg: true,
                        currentAccount: userAccount,
                      };
                      localStorage.setItem(
                        DOCUSIGN_KEY,
                        JSON.stringify(updatedDocuSignRemodeledData)
                      );
                      dispatch({
                        type: DOCUSIGN_SESSION,
                        payload: updatedDocuSignRemodeledData,
                      });
                      dispatch({
                        type: SET_DOCUSIGN_LOADER,
                        payload: false,
                      });

                      window.history.replaceState({}, document.title, window.location.pathname);
                    })
                    .catch((error) => {
                      // Handle any errors here
                      console.error('Error:', error);
                      dispatch({
                        type: SET_DOCUSIGN_LOADER,
                        payload: false,
                      });
                      throw new Error(error);
                    });
                } else {
                  enqueueSnackbar(messages.invalidDocuSignOrgMember, { variant: 'info' });
                  const updatedDocuSignRemodeledData = {
                    ...docuSignRemodeledData,
                    isValidOrg: false,
                    currentAccount: userAccount,
                  };
                  dispatch({
                    type: DOCUSIGN_SESSION,
                    payload: updatedDocuSignRemodeledData,
                  });
                  dispatch({
                    type: SET_DOCUSIGN_LOADER,
                    payload: false,
                  });
                  try {
                    await auth_axios.post(
                      `${authendpoints.authnew.webhook}/${configResponse?.docusign_account_id}/users/${docusign_user_info?.sub}`
                    );
                  } catch (err) {
                    throw new Error(err);
                  }
                  window.history.replaceState({}, document.title, window.location.pathname);
                }
              }
            })
            .catch((error) => {
              // Handle any errors here
              console.error('Error:', error);
              dispatch({
                type: SET_DOCUSIGN_LOADER,
                payload: false,
              });
              throw new Error(error);
            });
        }
      } else {
        dispatch({
          type: SET_DOCUSIGN_LOADER,
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_DOCUSIGN_LOADER,
        payload: false,
      });
      console.error('Failed to fetch single applications:', error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add_archive = useCallback(async (payload) => {
    try {
      const response = await auth_axios.delete(`${authendpoints.authnew.document}/${payload}`);
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const undo_archive = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(
        `${authendpoints.authnew.document}/${payload}/unarchive`
      );
      enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_archived_documents = useCallback(async (rowsPerPage, pageSize) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.archived_documents}?page=${pageSize}&size=${rowsPerPage}`
      );
      const totalCount = response?.data?.totalCount;
      const lastPage = response?.data?.lastPage;
      const payload = (response?.data?.data || []).map((item) => ({ ...item, isArchived: true }));

      dispatch({
        type: SET_ARCHIVED_DOCUMENTS,
        payload,
      });

      dispatch({
        type: SET_ARCHIVED_TOTAL_COUNT,
        payload: totalCount,
      });
      dispatch({
        type: SET_ARCHIVED_PAGE_COUNT,
        payload: lastPage,
      });
      return true;
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_ARCHIVED_DOCUMENTS,
        payload: null,
      });
      return false;
    }
  }, []);

  const store_file = useCallback(async (payload) => {
    try {
      dispatch({
        type: STORE_FRONT_PAGE_FILE,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handle_token_payment = useCallback(async (payload, url) => {
  //   try {
  //     const response = await auth_axios.post(
  //       `${PAYMENT_ENDPOINT}/${authendpoints.authnew.token_payment}`,
  //       payload
  //     );
  //     if (response?.data?.data) {
  //       // const destinationUrl = `http://localhost:3000/token-payment?email=${payload.emailId}&customerId=${response?.data?.data?.customerId}&integraPublicKeyId=${integraPublicKeyId}`;

  //       window.open(url, '_blank');
  //     } else {
  //       throw new Error();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  const blockchain_pubkey = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.integra_pub_key);
      return {
        PubKeyId: response?.data?.data?.PubKeyId,
        tokens: response?.data?.data?.tokens || 0,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const user_tour = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(authendpoints.authnew.user_tour, payload);
      await document_conversations();
    } catch (error) {
      console.error('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify_verifiable_presentation = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        authendpoints.authnew.verify_verifiable_presentation,
        payload
      );
      return true;
    } catch (error) {
      console.error('Error', error);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ceatePaymentIntent = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.payment_intent}`, payload);

      dispatch({
        type: SET_PAYMENT_DATA,
        payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const metaDataTypes = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.render_form_schema}/${payload}`
      );
      dispatch({
        type: META_DATA_RENDER,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      return null;
    }
  }, []);

  const fetch_document_schema_forms = useCallback(async (payload) => {
    try {
      // const response = await auth_axios.get(authendpoints.authnew.get_all_schema);
      const response = await auth_axios.get(`${authendpoints.authnew.get_all_schema}?${payload}`);
      dispatch({
        type: ALL_SCHEMA_FORMS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const new_fetch_document_schema_forms = useCallback(async (payload) => {
    try {
      // const response = await auth_axios.get(authendpoints.authnew.get_all_schema);
      const response = await auth_axios.get(`${authendpoints.authnew.get_all_schema}?${payload}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  const fetch_document_schema_type = useCallback(async (payload) => {
    try {
      const params = new URLSearchParams({ schemaType: payload });
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_all_schema}/type?${params.toString()}`
      );
      dispatch({
        type: ALL_SCHEMA_FORMS_TYPE,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);
  const get_document_schema_by_id = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.get_all_schema}/${payload}`);

      dispatch({
        type: SCHEMA_FORM_BY_ID,
        payload: response?.data,
      });
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
  }, []);

  const get_multiple_schema_by_id = useCallback(async (schemaIds) => {
    try {
      const idsParam = schemaIds.join(',');
      const response = await auth_axios.get(`${authendpoints.authnew.bulk_schema}?ids=${idsParam}`);
      dispatch({
        type: SCHEMA_FORM_BY_ID,
        payload: response?.data,
      });
    } catch (error) {
      console.error('Failed to fetch schema data:', error);
    }
  }, []);

  const get_user_created_schema = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.user_schema}`);

      dispatch({
        type: GET_USER_SCHEMAS,
        payload: response?.data,
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch bc transactions:', error);
    }
    return null;
  }, []);
  // eslint-disable-next-line consistent-return
  const get_user_created_schema_by_id = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.user_schema}/${payload}`);

      dispatch({
        type: GET_USER_SCHEMAS,
        payload: response?.data,
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
    }
  }, []);

  const post_user_template = useCallback(async (postBody) => {
    try {
      await auth_axios.post(authendpoints.authnew.user_schema, postBody);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      // return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_user_schema = useCallback(async (payload) => {
    try {
      const response = await auth_axios.get(`${authendpoints.authnew.user_schema}/${payload}`);

      dispatch({
        type: SET_USER_SCHEMA_BY_ID,
        payload: { userData: response?.data, time: Date.now() },
      });
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
    }
  }, []);

  const get_document_actions = useCallback(async () => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.get_all_schema_actions}?type=2`
      );
      dispatch({
        type: GET_DOCUMENT_ACTIONS,
        payload: response.data,
      });
    } catch (error) {
      console.error('Failed to fetch applications tags', error);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const actions_id = useCallback(async (schemaIds) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.bulk_schema}?ids=${schemaIds}`
      );
      dispatch({
        type: SET_ACTIONS_ID,
        payload: response?.data,
      });
      return response?.data;
    } catch (error) {
      console.error('Failed to fetch schema data:', error);
    }
  }, []);

  const fetchAssociateDocsByIntegraId = useCallback(async (integraId) => {
    try {
      const response = await auth_axios.get(
        `https://node1.integraledger.com/associateDocs/${integraId}`
      );
      return response.data;
    } catch (error) {
      console.error('Failed to fetch schema data:', error);
      return {
        success: false,
      };
    }
  }, []);

  const postGenerateSchema = useCallback(async (postBody) => {
    try {
      const response = await auth_axios.post(authendpoints.authnew.get_all_schema, postBody);
      enqueueSnackbar('Successfully imported document', { variant: 'success' });
      return response?.data;
    } catch (error) {
      console.error('*************Failed to post content:', error);
      enqueueSnackbar(error?.message, { variant: 'error' });
      return null;
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      fetch_content,
      new_fetch_document_schema_forms,
      get_document_actions,
      get_new_document_actions: state.get_new_document_actions,
      import_doc,
      post_myapplication,
      post_docusign_doc,
      post_update_docusign_doc,
      contents: state.contents,
      totalcount: state.totalcount,
      archivedTotalCount: state.archivedTotalCount,
      pagecount: state.pagecount,
      archivedPageCount: state.archivedPageCount,
      archived_docs_count: state.archived_docs_count,
      tokenizedcount: state.tokenizedcount,
      tokenizedContents: state.tokenizedContents,
      fetch_channels,
      post_channel,
      channels: state.channels,
      actions: state.actions,
      get_app_by_id,
      singleapp: state.singleapp,
      fetch_messages,
      post_message,
      single_conversation: state.single_conversation,
      messages: state.messages,
      getDocumentById,
      getApplicationById,
      fetch_transactions,
      transactions: state.transactions,
      fetch_applications,
      applications: state.applications,
      fetch_myapplications,
      myapplications: state.myapplications,
      remove_myapplications,
      remove_app_config,
      add_app_config,
      update_app_config,
      get_single_app_config,
      appConfig: state.appConfig,
      singleAppConfig: state.singleAppConfig,
      myAppDetail: state.myAppDetail,
      get_my_app_detail,
      transfer_docusign_doc,
      get_docusign_auth,
      get_app_tags,
      appTags: state.appTags,
      get_my_application_id_by_guid,
      appsSearchByTags: state.appsSearchByTags,
      get_my_application_search_by_tag,
      login_docusign_jwt_grant,
      docusign_data: state.docusign_data,
      isSessionLoading: state.isSessionLoading,
      docusign_authorization: state.docusign_authorization,
      add_new_application,
      update_application,
      remove_application,
      post_document_no_register,
      docusign_document_no_register,
      check_docusign_session,
      docusign_envelope,
      docusign_sending_cermony_link,
      envelope_data: state.envelope_data,
      docusign_envelope_recipient,
      verify_digest_signature,
      identity_exist_by_integraId,
      integra_identity_data: state.integra_identity_data,
      get_explorer_url,
      blockhain_data: state.blockhain_data,
      delete_doc,
      set_default_application,
      demo_data: state.demo_data,
      get_all_demo,
      single_demo_data: state.single_demo_data,
      get_demo,
      add_demo,
      update_demo,
      toggle_demo,
      reset_demo,
      delete_demo,
      app_defaults: state.app_defaults,
      get_app_defaults,
      update_default_apps,
      reset_default_apps,
      add_dynamic_forms,
      get_dynamic_forms,
      dynamic_forms: state.dynamic_forms,
      fetch_doc_data,
      doc_data: state.doc_data,
      org_data: state.org_data,
      get_org_details,
      establish_connection,
      all_conversations: state.all_conversations,
      document_conversations,
      is_document_conversations,
      update_conversations,
      update_conversation_status,
      post_message_file,
      post_docusign_doc_v2,
      docusign_session: state.docusign_session,
      append_messages,
      notifications: state.notifications,
      notificationsCount: state.notificationsCount,
      get_notifications,
      append_notifications,
      generate_key,
      trusted_data: state.trusted_data,
      check_org_trusted,
      signature_data: state.signature_data,
      generate_signature,
      txt_records: state.txt_records,
      get_txt_records,
      verify_dns_signature,
      all_templates: state.all_templates,
      get_all_templates,
      template_data: state.template_data,
      get_template_byID,
      update_template,
      send_verification_code,
      all_verifiable_credentials: state.all_verifiable_credentials,
      get_all_verifiable_credentials,
      generate_verifiable_credentials,
      generate_org_signature,
      org_signature: state.org_signature,
      verify_org_signature,
      get_actions,
      find_doc_by_integra_id,
      check_doc_by_integra_id,
      identity_exist_by_documentHash,
      delete_notifications,
      read_notifications,
      post_decrypt_comm_endpoint,
      read_chat,
      get_tokenized_data,
      patch_message,
      get_transfer_ownership,
      verify_verifiable_credentials,
      add_stepper_memory,
      stepper_memory: state.stepper_memory,
      clear_stepper_memory,
      get_RSA_keys,
      get_Identity_from_Blockchain,
      server_metadata: state.server_metadata,
      document_id: state.document_id,
      get_signature_validation,
      docusign_signature_check: state.docusign_signature_check,
      add_intel_config,
      pristamtic_auth_token,
      cyclr_auth_token,
      cyclr_marketplace,
      user_cyclr_marketplace,
      generate_cyclr_auth_token,
      integration_auth_token,
      update_intel_config,
      get_intel_config,
      intel_config: state.intel_config,
      frontPageFile: state.frontPageFile,
      implicit_grant_session,
      add_archive,
      undo_archive,
      get_archived_documents,
      store_file,
      archived_documents: state.archived_documents,
      // handle_token_payment,
      blockchain_pubkey,
      cyclr_token: state.cyclr_token,
      generate_cyclr_token: state.generate_cyclr_token,
      paymentData: state.paymentData,
      user_tour,
      verify_verifiable_presentation,
      ceatePaymentIntent,
      is_chat_loading: state.is_chat_loading,
      metaDataTypes,
      render_schema: state.render_schema,
      fetch_document_schema_type,
      all_schema_forms: state.all_schema_forms,
      all_schema_forms_types: state.all_schema_forms_types,
      fetch_document_schema_forms,
      get_document_schema_by_id,
      schema_forms_by_id: state.schema_forms_by_id,
      get_multiple_schema_by_id,
      multiple_schema_forms_data: state.multiple_schema_forms_data,
      get_user_created_schema,
      get_user_schemas: state.get_user_schemas,
      post_user_template,
      get_user_schema,
      get_user_schema_by_id: state.get_user_schema_by_id,
      user_schema_time_stamp: state.user_schema_time_stamp,
      actions_id,
      get_actions_id: state.get_actions_id,
      fetchAssociateDocsByIntegraId,
      postGenerateSchema,
      get_user_created_schema_by_id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetch_content,

      import_doc,
      post_myapplication,
      post_docusign_doc,
      post_update_docusign_doc,
      state.contents,
      state.totalcount,
      state.pagecount,
      state.archived_docs_count,
      state.tokenizedcount,
      state.tokenizedContents,
      state.channels,
      state.single_conversation,
      state.actions,
      state.messages,
      fetch_channels,
      post_channel,
      get_app_by_id,
      state.singleapp,
      fetch_messages,
      post_message,
      getDocumentById,
      getApplicationById,
      // addMessage,
      fetch_transactions,
      state.transactions,
      state.applications,
      fetch_applications,
      state.myapplications,
      fetch_myapplications,
      remove_myapplications,
      remove_app_config,
      add_app_config,
      update_app_config,
      // get_all_app_config,
      get_single_app_config,
      state.appConfig,
      state.singleAppConfig,
      state.myAppDetail,
      get_my_app_detail,
      transfer_docusign_doc,
      get_docusign_auth,
      get_app_tags,
      state.appTags,
      get_my_application_id_by_guid,
      state.appsSearchByTags,
      get_my_application_search_by_tag,
      login_docusign_jwt_grant,
      state.docusign_data,
      state.isSessionLoading,
      state.docusign_authorization,
      add_new_application,
      update_application,
      remove_application,
      post_document_no_register,
      docusign_document_no_register,
      check_docusign_session,
      docusign_envelope,
      docusign_sending_cermony_link,
      state.envelope_data,
      docusign_envelope_recipient,
      verify_digest_signature,
      identity_exist_by_integraId,
      state.integra_identity_data,
      get_explorer_url,
      state.blockhain_data,
      delete_doc,
      set_default_application,
      state.demo_data,
      get_all_demo,
      state.single_demo_data,
      get_demo,
      add_demo,
      update_demo,
      toggle_demo,
      reset_demo,
      delete_demo,
      state.app_defaults,
      get_app_defaults,
      update_default_apps,
      reset_default_apps,
      add_dynamic_forms,
      get_dynamic_forms,
      state.dynamic_forms,
      fetch_doc_data,
      state.doc_data,
      state.org_data,
      get_org_details,
      establish_connection,
      state.all_conversations,
      document_conversations,
      is_document_conversations,
      update_conversations,
      update_conversation_status,
      post_message_file,
      post_docusign_doc_v2,
      state.docusign_session,
      append_messages,
      state.notifications,
      state.notificationsCount,
      get_notifications,
      append_notifications,
      generate_key,
      state.trusted_data,
      check_org_trusted,
      state.signature_data,
      generate_signature,
      state.txt_records,
      get_txt_records,
      verify_dns_signature,
      state.all_templates,
      get_all_templates,
      state.template_data,
      get_template_byID,
      update_template,
      send_verification_code,
      state.all_verifiable_credentials,
      get_all_verifiable_credentials,
      generate_verifiable_credentials,
      generate_org_signature,
      state.org_signature,
      verify_org_signature,
      get_actions,
      find_doc_by_integra_id,
      check_doc_by_integra_id,
      identity_exist_by_documentHash,
      delete_notifications,
      read_notifications,
      post_decrypt_comm_endpoint,
      read_chat,
      get_tokenized_data,
      patch_message,
      get_transfer_ownership,
      verify_verifiable_credentials,
      add_stepper_memory,
      state.stepper_memory,
      clear_stepper_memory,
      get_RSA_keys,
      get_Identity_from_Blockchain,
      state.server_metadata,
      state.document_id,
      get_signature_validation,
      add_intel_config,
      pristamtic_auth_token,
      cyclr_auth_token,
      cyclr_marketplace,
      user_cyclr_marketplace,
      generate_cyclr_auth_token,
      integration_auth_token,
      update_intel_config,
      get_intel_config,
      state.intel_config,
      implicit_grant_session,
      add_archive,
      undo_archive,
      get_archived_documents,
      store_file,
      state.frontPageFile,
      state.archived_documents,
      // handle_token_payment,
      blockchain_pubkey,
      state.cyclr_token,
      state.generate_cyclr_token,
      user_tour,
      verify_verifiable_presentation,
      ceatePaymentIntent,
      state.paymentData,
      state.is_chat_loading,
      metaDataTypes,
      state.render_schema,
      fetch_document_schema_type,
      state.all_schema_forms,
      state.all_schema_forms_types,
      fetch_document_schema_forms,
      get_document_schema_by_id,
      state.schema_forms_by_id,
      get_multiple_schema_by_id,
      state.multiple_schema_forms_data,
      get_user_created_schema,
      state.get_user_schemas,
      post_user_template,
      get_user_schema,
      state.get_user_schema_by_id,
      state.user_schema_time_stamp,
      get_document_actions,
      state.get_new_document_actions,
      actions_id,
      state.get_actions_id,
      state.docusign_signature_check,
      fetchAssociateDocsByIntegraId,
      new_fetch_document_schema_forms,
      postGenerateSchema,
      get_user_created_schema_by_id,
    ]
  );

  return <ContentContext.Provider value={memoizedValue}>{children}</ContentContext.Provider>;
}

ContentContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ContentContextProvider;
