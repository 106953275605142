import { m } from 'framer-motion';
// @mui
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
// routes
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { useAuth } from 'react-oidc-context';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Label from 'src/components/label';
import { useSnackbar } from 'src/components/snackbar';
import { FRONTEND_VERSION } from 'src/config-globals';
import { UserType, SUPER_ORGANIZATION_NAME } from 'src/config/constants';
import { paths } from 'src/routes/paths';
import { useSocket } from 'src/store/socketContext';
import { profileUrlHanlder } from 'src/utils/urlHandler';

export default function AccountPopover() {
  const router = useRouter();
  const { logout, user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const popover = usePopover();
  const { disconnectSocket } = useSocket();
  const auth = useAuth();

  const OPTIONS = [
    // {
    //   label: 'Home',
    //   linkTo: '/',
    // },
    {
      label: 'Profile',
      linkTo: paths.dashboard.user.account,
    },
    ...(user?.role === UserType.ORG
      ? [
          {
            label: 'Organization',
            linkTo: paths.dashboard.organization.manage,
            // user?.organization_id
            //   ? paths.dashboard.organization.edit_organization(user?.organization_id)
            //   : paths.dashboard.organization.add_organization,
          },
          {
            label: 'Payments and Crypto',
            linkTo: paths.dashboard.cryptoPayments,
            // user?.organization_id
            //   ? paths.dashboard.organization.edit_organization(user?.organization_id)
            //   : paths.dashboard.organization.add_organization,
          },
          // {
          //   label: 'Manage Member',
          //   linkTo: user?.organization_id && paths.dashboard.members.list,
          // },
          // {
          //   label: 'Organization Verification',
          //   linkTo: paths.dashboard.organization.verify,
          // },
          // {
          //   label: 'Organization Data',
          //   linkTo: paths.dashboard.orgadmins,
          // },

          // {
          //   label: 'Organization Templates',
          //   linkTo: paths.dashboard.organization.templates,
          // },
        ]
      : []),
    ...(user?.role === UserType.ADMIN
      ? [
          {
            label: 'Manage Organization',
            linkTo: paths.dashboard.organization.list,
          },
          {
            label: 'Demo',
            linkTo: paths.dashboard.demo.root,
          },
          // {
          //   label: 'Document Schema',
          //   linkTo: paths.dashboard.editformschema,
          // },
        ]
      : []),
    ...(user?.role === UserType.ADMIN || user?.role === UserType.ORG
      ? [
          // {
          //   label: 'Create Forms',
          //   linkTo: paths.dashboard.forms.create,
          // },
          // TODO: Uncomment this item later
          // {
          //   label: 'JSON Schema',
          //   linkTo: paths.dashboard.forms.json_create,
          // },
          // {
          //   label: 'Forms Generator',
          //   linkTo: paths.dashboard.forms.generate,
          // },
        ]
      : []),
    ...(user?.organization?.organization_name === SUPER_ORGANIZATION_NAME
      ? [
          {
            label: 'Schema Editor',
            linkTo: paths.dashboard.forms.json_create,
          },
        ]
      : []),
    {
      label: 'Archived Documents',
      linkTo: paths.dashboard.archivedDocuments,
    },
  ];

  const handleLogout = async (sso_logout) => {
    try {
      disconnectSocket();
      await logout();
      popover.onClose();
      router.replace('/');
      window.Intercom('shutdown');

      await auth.removeUser();
      await auth.revokeTokens();
      window.location.reload();

      if (sso_logout) {
        await auth.signoutRedirect();
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {console.log(profileUrlHanlder(user?.photoURL), '########3')}
        <Avatar
          src={profileUrlHanlder(user?.photoURL)}
          alt={user?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          width: 200,
          p: 0,
          height: 'fit-content',
          minHeight: 'fit-content',
          maxHeight: 'fit-content',
        }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Label
            variant="soft"
            color={
              (user?.role === UserType.USER && 'success') ||
              (user?.role === UserType.ORG && 'warning') ||
              (user?.role === UserType.ADMIN && 'error') ||
              'default'
            }
          >
            {user?.role}
          </Label>
          <Typography variant="subtitle2" noWrap mt={1}>
            {user?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => handleLogout(false)}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
        {/* <MenuItem
          onClick={() => handleLogout(true)}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'warning.main' }}
        >
          Logout from SSO
        </MenuItem> */}
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            Version: {FRONTEND_VERSION}
          </Typography>
        </Box>
      </CustomPopover>
    </>
  );
}
