/* eslint-disable react-hooks/rules-of-hooks */
import { m } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';
// @mui
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ListItemAvatar } from '@mui/material';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// import { _notifications } from 'src/_mock';
// components
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { NotificationType } from 'src/config/constants';
//
import { useFormattedTime } from 'src/hooks/use-formatted-time';
import { useAuthContext } from 'src/auth/hooks';
import { ContentContext } from 'src/store/contentContext';
import { useSocket } from 'src/store/socketContext';
import NotificationItem from './notification-item';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { socket } = useSocket();
  const { user } = useAuthContext();

  const [currentTab, setCurrentTab] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [newNotificationData, setNewNotificationData] = useState();

  // const handleChangeTab = useCallback((event, newValue) => {
  //   setCurrentTab(newValue);
  // }, []);

  const {
    get_notifications,
    notifications: allNotifications,
    delete_notifications,
    read_notifications,
    notificationsCount,
  } = useContext(ContentContext);
  const [notifications, setNotifications] = useState([]);

  const handleChangeTab = useCallback(
    async (event, newValue) => {
      setCurrentTab(newValue);

      try {
        if (newValue === 'unread') {
          await get_notifications({ is_read: 2 });
        } else if (newValue === 'archived') {
          await get_notifications({ is_read: 1 });
        } else {
          await get_notifications();
        }
      } catch (error) {
        console.error('Error fetching tab notifications:', error);
      }
    },
    [get_notifications]
  );

  useEffect(() => {
    get_notifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setNotifications(allNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications]);

  const handleIconClick = async () => {
    try {
      const response = await get_notifications();
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  // const HandleDeleteNotifications = async () => {
  //   try {
  //     await delete_notifications();
  //   } catch (error) {
  //     console.error('Error deleting organization data:', error);
  //     // Handle error case (e.g., show error message)
  //   }
  // };

  const HandleDeleteNotifications = async () => {
    try {
      await delete_notifications();
      setNotifications([]); // Clear the notifications from the state to rerender the component
    } catch (error) {
      console.error('Error deleting notifications:', error);
      // Optionally show an error message or handle the error
    }
  };

  useEffect(() => {
    if (socket && user?.id) {
      socket.emit('users', { user_id: user?.id }, (data) => {
        console.log('emittted Data', data);
      });
    }
    if (socket) {
      console.log('enter in socket');
      socket.on('receiveNotification', (new_notification) => {
        setNotifications((prevNotifications) => [new_notification, ...prevNotifications]);
        setNewNotification(true);
        setNewNotificationData(new_notification);
        setIsModalOpen(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, user?.id]);

  const totalUnRead = notifications?.filter((item) => item.is_read === false).length;

  const renderAvatar = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Box
          component="img"
          src={`/assets/icons/notification/${
            (newNotificationData?.notification_type === NotificationType.DEFAULT && 'ic_order') ||
            (newNotificationData?.notification_type === NotificationType.REQUEST && 'ic_mail') ||
            (newNotificationData?.notification_type === NotificationType.MESSAGE && 'ic_chat') ||
            (newNotificationData?.notification_type === NotificationType.MESSAGE_ATTACHMENT &&
              'ic_chat') ||
            (newNotificationData?.notification_type === NotificationType.JSON_MESSAGE &&
              'ic_chat') ||
            // (newNotificationData.notification_type === NotificationType.MORE_DETAILS && 'ic_chat') ||
            (newNotificationData?.notification_type === NotificationType.TRANSFER && 'ic_chat') ||
            (newNotificationData?.notification_type === NotificationType.DOCUMENT_REGISTER &&
              'ic_chat')
          }.svg`}
          sx={{ width: 24, height: 24 }}
        />
      </Stack>
    </ListItemAvatar>
  );

  const renderModal = () => (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>You have a new notification</DialogTitle>

      <DialogContent>
        <Box sx={{ display: 'flex' }}>
          <Box> {renderAvatar}</Box>
          <Box mt={1} dangerouslySetInnerHTML={{ __html: newNotificationData?.content }} />
        </Box>
        {/* <Box>
          <Typography variant="body2">{newNotificationData?.message?.message}</Typography>
        </Box> */}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setIsModalOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const TABS = [
    {
      value: 'all',
      label: 'All',
      count: notificationsCount?.all,
    },
    {
      value: 'unread',
      label: 'Unread',
      count: notificationsCount?.unread,
    },
    {
      value: 'archived',
      label: 'Read',
      count: notificationsCount?.read,
    },
  ];

  const handleMarkAllAsRead = async () => {
    const notificationIds = notifications.map((notification) => notification.id);
    const payload = {
      notification_ids: notificationIds,
    };
    await read_notifications(payload);

    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     is_read: true,
    //   }))
    // );
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {Array.isArray(notifications) && notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onCloseDrawer={drawer.onFalse}
            />
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              textAlign: 'center',
              paddingTop: '20px', // Adjust the value as needed
              fontWeight: 'bold', // Make the text bold
              fontSize: '16px', // You can adjust the font size as well
            }}
          >
            No notifications available
          </div>
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        {/* uncomment the below code if you want a dialog when new notificaation renders */}
        {/* {newNotification && renderModal()} */}
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} onClick={handleIconClick} />
        </Badge>
      </IconButton>
      <style>{`
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`}</style>
      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          {!!totalUnRead && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            size="large"
            onClick={HandleDeleteNotifications}
            variant="contained"
            color="primary"
          >
            Clear All Notifications
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
