import { matchPath, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useActiveLink(path, deep = true) {
  const { pathname, search } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, `${pathname}${search}`) : false;
  const deepActive = path ? !!matchPath({ path, end: false }, `${pathname}${search}`) : false;
  return deep ? deepActive : normalActive;
}
