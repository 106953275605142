// utils
import { _id, _postTitles } from 'src/_mock/assets';
import { paramCase } from 'src/utils/change-case';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  ADMIN: '/admin',
  DOCUSIGN_EMAIL_REDIRECTION: '/signing_ceremony',
  INTEL: '/intel',
  CLIO: '/clio',
};

// ----------------------------------------------------------------------

export const paths = {
  // comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  verifiedbyintegra: '/verifiedbyintegra',
  faqs: '/faqs',
  authredirect:  '/authredirect',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',

  verify: '/verify',
  signingkey: '/signingkey',
  verify_wells: '/verify_wells',
  verify_identity: '/verify_identity',
  get_key_page: '/getKeyPage',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    frontpage: `${ROOTS.AUTH}/frontpage`,
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/login`,
      updatePassword: `${ROOTS.AUTH}/jwt/update-password`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
      verifyToken: `${ROOTS.AUTH}/jwt/verify-token`,
      verifySuccess: `${ROOTS.AUTH}/jwt/verification-successful`,

      // verify: `${ROOTS.AUTH}/jwt/verify`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    // chat: (cid) => `${ROOTS.DASHBOARD}/chat?channelId=${cid}`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    payments: `${ROOTS.DASHBOARD}/crypto-payments`,
    // apps: `${ROOTS.DASHBOARD}/apps`,
    // myapps: `${ROOTS.DASHBOARD}/myapps`,
    contacts: `${ROOTS.DASHBOARD}/contacts`,
    explorer: `${ROOTS.DASHBOARD}/explorer`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    cryptoPayments: `${ROOTS.DASHBOARD}/crypto-payments`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      createsmartdoc: `${ROOTS.DASHBOARD}/createsmartdoc`,
      documentdetails: `${ROOTS.DASHBOARD}/documentdetails`,
      transfersmartdoc: `${ROOTS.DASHBOARD}/transfersmartdoc`,
      receivesmartdoc: `${ROOTS.DASHBOARD}/receivesmartdoc`,
      verifysmartdoc: `${ROOTS.DASHBOARD}/verifysmartdoc`,
      importsmartdoc: `${ROOTS.DASHBOARD}/importsmartdoc`,
      channels: `${ROOTS.DASHBOARD}/channels`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
      attach_document: `${ROOTS.DASHBOARD}/attach_document`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
      verify: `${ROOTS.DASHBOARD}/user/verify`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    apps: {
      root: `${ROOTS.DASHBOARD}/apps`,
      new: `${ROOTS.DASHBOARD}/apps/new`,
      details: (id) => `${ROOTS.DASHBOARD}/apps/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/apps/${id}/edit`,
      docusign: (id) => `${ROOTS.DASHBOARD}/apps/docusign/${id}`,
    },
    myapps: {
      root: `${ROOTS.DASHBOARD}/myapps`,
      // new: `${ROOTS.DASHBOARD}/apps/new`,
      details: (id) => `${ROOTS.DASHBOARD}/myapps/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      docusign: (id) => `${ROOTS.DASHBOARD}/apps/docusign/${id}`,
      config: (id) => `${ROOTS.DASHBOARD}/myapps/config/${id}`,
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      setting: (id) => `${ROOTS.DASHBOARD}/myapps/detail/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
    organization: {
      manage: `${ROOTS.DASHBOARD}/org`,
      list: `${ROOTS.DASHBOARD}/org/list`,
      add_organization: `${ROOTS.DASHBOARD}/org/new`,
      edit_organization: (id) => `${ROOTS.DASHBOARD}/org/${id}`,
      verify: `${ROOTS.DASHBOARD}/org/verify`,
      templates: `${ROOTS.DASHBOARD}/org/templates`,
      edit_template: (id) => `${ROOTS.DASHBOARD}/org/templates/${id}`,
    },
    members: {
      list: `${ROOTS.DASHBOARD}/members`,
      add_member: `${ROOTS.DASHBOARD}/members/new`,
      edit_member: (id) => `${ROOTS.DASHBOARD}/members/${id}`,
    },
    demo: {
      root: `${ROOTS.DASHBOARD}/demo`,
      new: `${ROOTS.DASHBOARD}/demo/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/demo/${id}/edit`,
    },
    forms: {
      create: `${ROOTS.DASHBOARD}/forms/create`,
      json_create: `${ROOTS.DASHBOARD}/forms/json-create`,
      generate: `${ROOTS.DASHBOARD}/forms/generate`,
    },
    registration: `${ROOTS.DASHBOARD}/registration`,
    file_action: `${ROOTS.DASHBOARD}/file-action`,
    confirmation: `${ROOTS.DASHBOARD}/confirmation`,
    resultView: `${ROOTS.DASHBOARD}/resultview`,
    orgadmins: `${ROOTS.DASHBOARD}/orgadmins`,
    forgotPassword: `${ROOTS.AUTH}/forgotpassword`,
    tokenizedOwner: `${ROOTS.DASHBOARD}/file-manager?tab=tokens`,
    intel: {
      root: `${ROOTS.DASHBOARD}/intel`,
      config: `${ROOTS.DASHBOARD}/intel/config`,
    },
    clio: {
      root: `${ROOTS.DASHBOARD}/clio`,
      config: `${ROOTS.DASHBOARD}/clio/config`,
    },
    eSignature: `${ROOTS.DASHBOARD}/e-signature`,
    archivedDocuments: `${ROOTS.DASHBOARD}/archived-documents`,
    comingSoon: `${ROOTS.DASHBOARD}/zapier`,
    prismaticView: `${ROOTS.DASHBOARD}/prismatic`,
    cyclrView: `${ROOTS.DASHBOARD}/cyclr`,
    vimeoView: `${ROOTS.DASHBOARD}/vimeo-player`,
    cyclrMarketplace: `${ROOTS.DASHBOARD}/marketplace`,
    generalMarketplace: `${ROOTS.DASHBOARD}/general-marketplace`,
    transfertoken: `${ROOTS.DASHBOARD}/transfer-token`,
  },
  // admin

  // MAIN
  intel: {
    root: `${ROOTS.INTEL}/new-contract`,
    newContract: `${ROOTS.INTEL}/new-contract`,
    app: `${ROOTS.INTEL}/new-contract`,
    config: `${ROOTS.INTEL}/new-contract`,
  },
  clio: {
    temp: `${ROOTS.CLIO}/temp`,
  },

  docusign: {
    signing_ceremony: `${ROOTS.DOCUSIGN_EMAIL_REDIRECTION}`,
  },
};
