import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Stack,
} from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PropTypes from 'prop-types';

export default function ResizeImageDialog({ open, imageFile, onClose, onSave }) {
  const [scale, setScale] = useState(1);
  const avatarEditorRef = useRef();

  const imageSrc = useMemo(() => {
    if (open) {
      const blob = URL.createObjectURL(imageFile);
      return blob;
    }
    return '';
  }, [imageFile, open]);

  const handleScaleChange = (_, newValue) => {
    setScale(newValue);
  };

  const handleSave = async () => {
    const dataUrl = avatarEditorRef.current.getImage().toDataURL();
    const res = await fetch(dataUrl);
    const blob = await res.blob();

    const croppedFile = new File([blob], imageFile.name);
    onSave(croppedFile);
  };

  useEffect(() => {
    if (!open) {
      setScale(1);
    }
  }, [open]);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Resize Image</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <AvatarEditor
            ref={avatarEditorRef}
            width={250}
            height={250}
            scale={scale}
            image={imageSrc}
          />
          <Box flex="1" mt={1}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ alignItems: 'center', mb: 1 }}
              minWidth="200px"
            >
              <ZoomOutIcon />

              <Slider
                aria-label="Volume"
                value={scale}
                min={1}
                max={10}
                onChange={handleScaleChange}
              />
              <ZoomInIcon />
            </Stack>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResizeImageDialog.propTypes = {
  open: PropTypes.bool,
  imageFile: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
