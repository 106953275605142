import { useEffect, useState } from 'react';

export function useIsMobile(maxWidth = 768) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check initial window width
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= maxWidth);
    };

    // Run initial check
    checkMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkMobile);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [maxWidth]);

  return isMobile;
}
